import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const eventIsArchived = this.data.get("archived");

    if (eventIsArchived == "true") {
      const formFields = this.element.querySelectorAll(["select", "input[type=text]", "input[type=file]", "input[type=number]", "input[type=submit]"]);

      for(var field of formFields) {
        field.disabled = eventIsArchived;
        field.readonly = eventIsArchived;
      }
    }
  }
}
