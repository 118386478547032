import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "true",
    "false",
    "afterConfirmationTrue",
    "afterConfirmationFalse"
  ]

  connect() {
    this.disabled = !this.cancellable

    if (!this.cancellable) {
      this.afterConfirmation = false
    }
  }

  update() {
    if (this.cancellable) {
      this.disabled = false
      this.afterConfirmation = true
    } else {
      this.disabled = true
      this.afterConfirmation = false
    }
  }

  get cancellable() {
    return this.trueTarget.checked
  }

  set disabled(value) {
    this.afterConfirmationTrueTarget.disabled = value
    this.afterConfirmationFalseTarget.disabled = value
  }

  set afterConfirmation(value) {
    if (value) {
      this.afterConfirmationTrueTarget.checked = true
      this.afterConfirmationFalseTarget.checked = false
    } else {
      this.afterConfirmationTrueTarget.checked = false
      this.afterConfirmationFalseTarget.checked = false
    }
  }
}
