import { Controller } from 'stimulus';
import { setTooltip, hideTooltip, copyToClipboard } from "../shared";

export default class extends Controller {
    static targets = [ "preview", "previewContainer", "password" ];

    connect() {
        if (!document.getElementById("fan_portal_preview_enabled").checked) {
            this.hidePasswordInputAndButtons()
        }
        this.removeUnnecessaryClassFromPreviewLinkButton();
    }

    onMouseEnterPreview() {
        if (document.getElementById("fan_portal_preview_enabled").disabled) {
            setTooltip(this.previewContainerTarget.id, "Preview not available.")
        }
    }

    onMouseLeavePreview() {
        if (document.getElementById("fan_portal_preview_enabled").disabled) {
            hideTooltip(this.previewContainerTarget.id)
        }
    }

    onCopyPreviewLink() {
        const currentInputPassword = document.getElementById("fan_portal_password").value
        const currentSavedPassword = this.element.dataset.currentPasswordValue
        const fanPortalUrl = this.element.dataset.fanPortalUrlValue

        if (currentInputPassword && currentSavedPassword) {
            copyToClipboard(fanPortalUrl)
        }
    }

    onTogglePreview() {
        if (this.previewTarget.checked) {
            this.showPasswordInputAndButtons()
        } else {
            this.clearAndHidePasswordInput()
        }
    }

    clearAndHidePasswordInput() {
        this.passwordTarget.value = null
        this.hidePasswordInputAndButtons();
    }

    showPasswordInputAndButtons() {
        document.getElementById("password-group").classList.remove("d-none")
        document.getElementById("password-buttons-group").classList.remove("d-none")
    }

    hidePasswordInputAndButtons() {
        document.getElementById("password-group").classList.add("d-none")
        document.getElementById("password-buttons-group").classList.add("d-none")
    }

    // Class is automatically, unintentionally added to this button because it's used as rails form submit button
    removeUnnecessaryClassFromPreviewLinkButton() {
        document.getElementById("copy-preview-link").classList.remove("btn-primary")
    }
}