import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["email"]

  confirm(event) {
    if (!this.email) {
      event.preventDefault()
      alert("Please enter an email address to exclude")
    } else if (!confirm(this.prompt)) {
      event.preventDefault()
    }
  }

  get email() {
    return this.emailTarget.value
  }

  get prompt() {
    return `Are you sure you want to permanently exclude ${this.email} from all events?`;
  }
}
