import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "status", "partnerTotal" ]

  connect() {
    this.capacity = parseInt(this.data.get("capacity"))
    this.updateTotals()
  }

  updateTotals() {
    var totalElement = document.createElement("span");
    totalElement.textContent = `${this.capacity} Total`;

    this.allocated = this.totalAllocation()

    if (this.hasPartnerTotalTarget) {
      this.partnerAllocation = this.totalPartnerAllocation()
    }

    var allocatedElement = document.createElement("span");
    allocatedElement.textContent = `${this.allocated} Allocated`;

    if (this.isOverCapacity()) {
      allocatedElement.classList = "text-danger";
    } else if (this.isNearCapacity()) {
      allocatedElement.classList = "text-warning";
    } else {
      allocatedElement.classList = "text-success";
    }

    this.statusTarget.replaceChildren(totalElement, " / ", allocatedElement);

    if (this.hasPartnerTotalTarget) {
      this.partnerTotalTarget.value = this.partnerAllocation
    }
  }

  totalAllocation() {
    return this.totalFields("input.seat-allocation")
  }

  totalPartnerAllocation() {
    return this.totalFields("input.partner-seat-allocation")
  }

  totalFields(selector) {
    var fields = this.element.querySelectorAll(selector)
    var total = 0

    for (var field of fields) {
      var allocation = parseInt(field.value)

      if (!isNaN(allocation)) {
        total += allocation
      }
    }

    return total
  }

  allocationChanged(event) {
    this.updateTotals()
  }

  isNearCapacity() {
    return this.percentAllocated() >= 0.8
  }

  isOverCapacity() {
    return this.percentAllocated() > 1
  }

  percentAllocated() {
    return this.allocated / this.capacity
  }
}
