import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "ageVerificationRequired",
    "requiredAge",
    "birthDateRequired",
    "consentCheckbox",
    "consentRequired"
  ]

  connect() {
    if (this.data.get("archived") === "false") {
      this.updateRequiredAgeField()
      this.updateRequireAgeVerificationField()
      this.updateConsentRequiredField()
    }
  }

  updateRequiredAgeField() {
    this.requiredAgeTarget.disabled = !this.ageVerificationRequiredTarget.checked
  }

  updateRequireAgeVerificationField() {
    if (this.birthDateRequiredTarget.checked) {
      this.ageVerificationRequiredTarget.disabled = false
    } else {
      this.ageVerificationRequiredTarget.checked = false
      this.ageVerificationRequiredTarget.disabled = true
      this.requiredAgeTarget.disabled = true
    }
  }
  
  updateConsentRequiredField() {
    if (this.consentCheckboxTarget.checked) {
      this.consentRequiredTarget.disabled = false
    } else {
      this.consentRequiredTarget.checked = false
      this.consentRequiredTarget.disabled = true
    }
  }
}
