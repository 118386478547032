import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "inputField", "selectField"
  ]

  fillInField() {
    this.inputFieldTarget.value = this.selectFieldTarget.value
  }
}
