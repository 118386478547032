import { Controller } from "stimulus"
import * as Chartist from "chartist"
import "chartist-plugin-legend"
import "chartist-plugin-tooltips"
import moment from "moment-timezone"

export default class extends Controller {
  static targets = ["lineChart", "lineChartLegend", "lineChartId"];

  connect() {
    this.initializeChart(this.lineChartIdTarget)
  }

  // Chart animation
  displayChart(seriesData, lineChartLabels, lineChartLegendId) {
    const timezone = this.lineChartIdTarget.getAttribute("data-timezone");
    const data = {
      series: seriesData
    }
    const options = {
      fullWidth: true,
      fullHeight: true,
      chartPadding: {
        left: 40
      },
      showArea: true,
      axisY: {
        onlyInteger: true,
      },
      axisX: {
        type: Chartist.FixedScaleAxis,
        ticks: lineChartLabels,
        labelInterpolationFnc: function(value, index, labels) {
          let labelScale = Math.round((labels.length + 30) / 30)
          var momentValue = moment(value).tz(timezone).format('HH:mm DD/MM/YY')
          if (labels.length > 30) {
            return index % labelScale  === 0 ? momentValue : ""
          } else {
            return momentValue
          }
        }
      },
      plugins: [
        Chartist.plugins.legend({
          position: lineChartLegendId,
          clickable: false
        })
      ]
    }
    const chart = new Chartist.Line(this.lineChartTarget, data, options);

    if (!lineChartLegendId == "") {
      this.displayChartLegend(chart)
    }
  }

  displayChartLegend(chart) {
    const chartLegend = this;

    chart.on('draw', function(context) {
      const guestLists = JSON.parse(chartLegend.lineChartIdTarget.getAttribute("data-guest-lists"));

      if (context.type == 'line') {
        const className = context.series.className;
        const legendClassTarget = `.ct-legend li.ct-series-${context.index}`;
        const legendClassStyleTarget = `${legendClassTarget}:before`;

        switch (className) {
          case "ct-series-fans":
            if (!guestLists.includes("fans")) {
              chartLegend.addRule(legendClassTarget, "display: none");
            }
            break;

          case "ct-series-guests":
            if (!guestLists.includes("guests")) {
              chartLegend.addRule(legendClassTarget, "display: none");
            }
            break;

          case "ct-series-pr":
            if (!guestLists.includes("pr")) {
              chartLegend.addRule(legendClassTarget, "display: none");
            }
            break;

          case "ct-series-partners":
            if (!guestLists.includes("partners")) {
              chartLegend.addRule(legendClassTarget, "display: none");
            }
            break;
        }
      }
    })
  }

  // Chart data
  initializeChart(chartId) {
    const lineChartData = JSON.parse(chartId.getAttribute("data-chart"));

    switch (this.lineChartIdTarget.id) {
      case "event-entrants":
        var seriesData = this.getValues(lineChartData);
        var lineChartLabels = this.getTimeLabels(lineChartData, 1, 60);
        var chartLegend = ""
        break;

      case "event-confirmations":
        var seriesData = this.getStackedValues(lineChartData);
        var lineChartLabels = this.getTimeLabels(lineChartData, 4, 240);
        var chartLegend = this.lineChartLegendTarget
        break;
    }

    this.displayChart(seriesData, lineChartLabels, chartLegend)
  }

  getValues(chartData) {
    var values = []
    values.push({x: this.getBeginningOfHour(parseInt(Object.keys(chartData)[0])), y: null})
    for (var i = 0, keys = Object.keys(chartData), ii = keys.length; i < ii; i++) {
      values.push({x: new Date(parseInt(Object.keys(chartData)[i])), y: Object.values(chartData)[i]})
    }
    values.push({x: this.getEndOfHour(parseInt(Object.keys(chartData)[Object.keys(chartData).length - 1])), y: null})

    var data = [{ data: values }]
    return data
  }

  getStackedValues(chartData) {
    let fanValues = [], guestValues = [], prValues = [], partnerValues = [];
    const guestsValues = Object.values(chartData);
    const timeData = Object.keys(chartData);

    const partnerValuesStacked = guestsValues.map((value) => value[3]);
    const prValuesStacked = this.calculateStackedValues(guestsValues, 2, partnerValuesStacked);
    const guestValuesStacked = this.calculateStackedValues(guestsValues, 1, prValuesStacked);
    const fanValuesStacked = this.calculateStackedValues(guestsValues, 0, guestValuesStacked);

    timeData.forEach((time, index) => {
      partnerValues.push({x: new Date(parseInt(time)), y: partnerValuesStacked[index]})
      prValues.push({x: new Date(parseInt(time)), y: prValuesStacked[index]})
      guestValues.push({x: new Date(parseInt(time)), y: guestValuesStacked[index]})
      fanValues.push({x: new Date(parseInt(time)), y: fanValuesStacked[index]})
    })

    const data = [
      {
        value: fanValues,
        className: "ct-series-fans",
        name: "Fans"
      },
      {
        value: guestValues,
        className: "ct-series-guests",
        name: "Guests"
      },
      {
        value: prValues,
        className: "ct-series-pr",
        name: "PR"
      },
      {
        value: partnerValues,
        className: "ct-series-partners",
        name: "Partners"
      }
    ]

    return data
  }

  getTimeLabels(chartData, hours, multiplier) {
    var labels = []
    var date = this.getBeginningOfHour(parseInt(Object.keys(chartData)[0]))
    for(var i = 0, ii = this.getNumberOfHours(chartData, multiplier); i < ii; i++) {
      labels.push(date)
      var date = new Date(date)
      date.setHours(date.getHours() + hours)
    }
    labels.push(this.getEndOfHour(parseInt(Object.keys(chartData)[Object.keys(chartData).length - 1])))

    return labels
  }

  // Helpers
  getNumberOfHours(chartData, multiplier) {
    return (this.getEndOfHour(parseInt(Object.keys(chartData)[Object.keys(chartData).length - 1])) - this.getBeginningOfHour(parseInt(Object.keys(chartData)[0]))) / (multiplier*60*1000)
  }

  getBeginningOfHour(milliseconds) {
    return (new Date(moment(milliseconds).startOf("hour").valueOf()).getTime())
  }

  getEndOfHour(milliseconds, hours) {
    return (new Date(moment(this.getBeginningOfHour(milliseconds)).add(hours, "hour")).getTime())
  }

  calculateStackedValues(confirmationValues, index, previousValuesStacked) {
    const valuesArray = confirmationValues.map((value) => {
      return value[index];
    })

    return this.sumArrays(previousValuesStacked, valuesArray);
  }

  sumArrays(array1, array2) {
    const sum = array1.map((n, i) => {
      return n + array2[i];
    });

    return sum;
  }

  addRule(selector, css) {
    const styleEl = document.createElement('style');
    const sheet = document.head.appendChild(styleEl).sheet;
    const propText = `${css};`;

    sheet.addRule(selector, propText);
  }
}
