import { Controller } from 'stimulus';
import Trix from 'trix';

Trix.config.textAttributes.color = {
  styleProperty: 'color',
  inheritable: true,
};

Trix.config.textAttributes.fontSize = {
  styleProperty: 'fontSize',
  inheritable: true,
};

Trix.config.blockAttributes.alignLeft = {
  tagName: 'align-left',
  parse: true,
  nestable: false,
  exclusive: true,
};

Trix.config.blockAttributes.alignCenter = {
  tagName: 'align-center',
  parse: true,
  nestable: false,
  exclusive: true,
};

Trix.config.blockAttributes.alignRight = {
  tagName: 'align-right',
  parse: true,
  nestable: false,
  exclusive: true,
};

// Turn off the default Trix captions
Trix.config.attachments.preview.caption = {
  name: false,
  size: false,
};

// Use the caption field to capture some alt text
Trix.config.lang.captionPlaceholder = 'Add alt text…';

const intToHex = function (number) {
  return ('0' + parseInt(number).toString(16)).slice(-2);
};

const rgbToHex = function (color) {
  const rgb = color.match(
    /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/
  );
  return `#${intToHex(rgb[1])}${intToHex(rgb[2])}${intToHex(rgb[3])}`;
};

const fontSizes = [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

export default class extends Controller {
  static targets = ['editor', 'colorPicker', 'fontSizeSelector'];

  connect() {
    // A slight delay is needed for the trix editor elements
    // to initialize before we can add our own
    setTimeout(() => {
      this.insertColorButton();
      this.insertAlignmentButtons();
      this.insertFontSizeSelector();
      this.removeAttachmentButton();
      this.refreshAttributeButtons();
      this.initializeColorPicker();
      this.initializeFontSizeSelector();
      this.initializeEvents(this.editor);
    }, 100);
  }

  get editor() {
    return this.editorTarget;
  }

  get defaultFontSize() {
    return parseInt(getComputedStyle(this.editor).fontSize);
  }

  colorChanged(event) {
    this.trix.recordUndoEntry('Content updated');
    this.trix.activateAttribute('color', this.textColor);
  }

  fontSizeChanged(event) {
    this.trix.recordUndoEntry('Content updated');
    this.trix.activateAttribute('fontSize', `${this.fontSize}px`);
  }

  initializeColorPicker(event) {
    this.colorPicker.value = this.defaultTextColor;
  }

  initializeFontSizeSelector(event) {
    this.fontSizeSelector.value = this.defaultFontSize;
  }

  initializeEvents(editor) {
    const callback = (e) => {
      this.updateToolbar(e);
    };

    const fileAcceptEventCallback = (e) => {
      if (this.shouldRemoveAttachmentButton) {
        e.preventDefault();
        return false;
      }

      // Only whitelist certain file extensions
      const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!acceptedTypes.includes(e.file.type)) {
        e.preventDefault();

        alert(
          'File attachment not supported! You may add only jpeg, png or gif files.'
        );
      }

      // Only allow file size up to 1MB
      const maxFileSize = 1024 * 1024;

      if (e.file.size > maxFileSize) {
        e.preventDefault();

        alert(
          'File size is too large! You may only attach files up to 1MB in size.'
        );
      }
    };

    editor.addEventListener('trix-change', callback);
    editor.addEventListener('trix-focus', callback);
    editor.addEventListener('trix-selection-change', callback);
    editor.addEventListener(
      'trix-file-accept',
      fileAcceptEventCallback
    );
  }

  updateToolbar(event) {
    this.updateColorPicker(event);
    this.updateFontSizeSelector(event);
  }

  updateColorPicker(event) {
    this.colorPicker.value = this.currentTextColor;
  }

  updateFontSizeSelector(fontSize) {
    let newFontSize = parseInt(this.currentFontSize);
    let fontSizeValid = fontSizes.includes(newFontSize);
    this.fontSizeSelector.value =
      fontSizeValid && !this.isEmpty
        ? newFontSize
        : this.defaultFontSize;
  }

  get editor() {
    return this.editorTarget;
  }

  get toolbar() {
    return this.editor.previousSibling;
  }

  get colorPicker() {
    return this.colorPickerTarget;
  }

  get fontSizeSelector() {
    return this.fontSizeSelectorTarget;
  }

  get trix() {
    return this.editorTarget.editor;
  }

  get isEmpty() {
    return this.trix.getDocument().isEmpty();
  }

  get textColor() {
    return this.colorPicker.value;
  }

  get fontSize() {
    return this.fontSizeSelector.value;
  }

  get currentTextColor() {
    const colorAttibute = this.pieceAtCursor.getAttribute('color');

    if (colorAttibute) {
      return colorAttibute;
    } else {
      return this.defaultTextColor;
    }
  }

  get currentFontSize() {
    const fontSizeAttribute =
      this.pieceAtCursor.getAttribute('fontSize');

    if (fontSizeAttribute) {
      // Since the value is '16px'
      return fontSizeAttribute.slice(0, 2);
    } else {
      return this.defaultFontSize;
    }
  }

  get colorsElement() {
    return document.querySelector(
      "[data-controller='rover--colors']"
    );
  }

  get defaultTextColor() {
    return rgbToHex(getComputedStyle(this.editor).color);
  }

  get pieceAtCursor() {
    return this.trix
      .getDocument()
      .getPieceAtPosition(this.trix.getPosition());
  }

  insertColorButton() {
    const button = this.toolbar.querySelector(
      '.trix-button-row .trix-button-group:first-child .trix-button:last-child'
    );
    const html = `
      <input type="color" class="trix-button" data-action="input->trix#colorChanged" data-target="trix.colorPicker">
    `;

    button.insertAdjacentHTML('afterend', html);
  }

  insertFontSizeSelector() {
    const button = this.toolbar.querySelector(
      '.trix-button-row .trix-button-group:first-child .trix-button:last-child'
    );

    let options = fontSizes.map((size) => `<option>${size}</option>`);

    const html = `
        <select name="font-size" class="trix-button" data-action="trix#fontSizeChanged" data-target="trix.fontSizeSelector">
          ${options}
        </select>
      `;

    button.insertAdjacentHTML('afterend', html);
  }

  insertAlignmentButtons() {
    const group = this.toolbar.querySelector('.trix-button-row');
    const html = `
      <div class="trix-button-row">
        <span class="trix-button-group trix-button-group--alignment-tools" data-trix-button-group="alignment-tools">
          <button type="button" class="trix-button trix-button--icon trix-button--icon-align-left" data-trix-attribute="alignLeft" title="Align Left" tabindex="-1">Align Left</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-align-center" data-trix-attribute="alignCenter" title="Align Center" tabindex="-1">Align Center</button>
          <button type="button" class="trix-button trix-button--icon trix-button--icon-align-right" data-trix-attribute="alignRight" title="Align Right" tabindex="-1">Align Right</button>
        </span>
      </div>
    `;

    group.insertAdjacentHTML('afterend', html);
  }

  get shouldRemoveAttachmentButton() {
    return this.data.get('hide-attachment-button') ? true : false;
  }

  removeAttachmentButton() {
    if (this.shouldRemoveAttachmentButton) {
      this.toolbar
        .querySelector('.trix-button-group--file-tools')
        .remove();
    }
  }

  refreshAttributeButtons() {
    this.editor.editorController.toolbarController.refreshAttributeButtons();
  }
}

// Custom HTML elements used for aligning blocks
class BaseElement extends HTMLElement {
  constructor() {
    super();
    this.attachShadow({ mode: 'open' });
  }
}

function innerHTML(alignment) {
  return `
    <style>
      :host {
        text-align: ${alignment};
        width: 100%;
        display: block;
      }
    </style>

    <slot></slot>
  `;
}

export class AlignLeftElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML('left');
  }
}

export class AlignCenterElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML('center');
  }
}

export class AlignRightElement extends BaseElement {
  constructor() {
    super();

    this.shadowRoot.innerHTML = innerHTML('right');
  }
}

window.customElements.define('align-left', AlignLeftElement);
window.customElements.define('align-center', AlignCenterElement);
window.customElements.define('align-right', AlignRightElement);
