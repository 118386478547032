import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "label" ]

  connect() {
    if (this.inputTarget.dataset.attached) {
      this.labelTarget.classList.remove("custom-file-label-pending")
    }
  }

  showFilename() {
    // This normally reflects a sandboxed path - something like
    // "C:\fakepath\file-name-over-here.jpg", so change
    // it to remove the fake path - just include the file fragment
    var fileName = this.inputTarget.value.split(/(\\|\/)/g).pop()
    this.labelTarget.textContent = fileName

    // Remove the custom-file-label-pending class from the associated label,
    // so that it's easier to see where someone has uploaded
    this.labelTarget.classList.remove("custom-file-label-pending")
  }
}
