import { Controller } from "stimulus"
import Popper from 'popper.js';

export default class extends Controller {
  static targets = [
    "container",
    "button",
    "popover",
    "arrow"
  ]

  connect() {
    this.placement = this.data.get("placement");
    this.trigger = this.data.get("trigger");
    this.header = this.data.get("header");
    this.content = this.data.get("content");
    this.buildHTML();
    this.addEventListeners();
    this.newPopper();
  }

  buildHTML() {
    const header = this.header
      ? `<h3 class="popover-header" data-target="popper.header">${this.header}</h3>`
      : null;

    const html = `
        <div class="popover bs-popover-${this.placement} fade" role="tooltip" data-target="popper.popover">
          <div class="arrow" data-target="popper.arrow"></div>
          ${header ? header : ''}
          <div class="popover-body" data-target="popper.body">${this.content}</div>
        </div>
      `;

    this.containerTarget.insertAdjacentHTML("afterbegin", html);
  }

  // If more event listeners are needed just add them here
  addEventListeners() {
    switch(this.trigger) {
      case "onhover":
        this.containerTarget.onmouseover = this.toggleContainer.bind(this);
        this.containerTarget.onmouseout = this.toggleContainer.bind(this);
        break;
    }
  }

  toggleContainer(e) {
    const showClass = "show";
    let visible = this.popoverTarget.classList.contains(showClass);
    if ( visible ) {
      this.popoverTarget.classList.remove(showClass);
    } else {
      this.popoverTarget.classList.add(showClass);
    }
  }

  newPopper() {
    return new Popper(this.buttonTarget, this.popoverTarget, {
      placement: this.placement,
      modifiers: {
        arrow: {
          enabled: true,
          element: this.arrowTarget
        }
      }
    });
  }
}
