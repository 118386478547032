import { Controller } from "stimulus"
import SVG from "svg.js"
import "svg.draw.js"
import "svg.panzoom.js"

export default class extends Controller {
  static targets = [
    "moveTool", "arrowTool", "boxTool",
    "statusSection", "statusRow", "statusSeat",
    "unavailableTool", "unconfirmedTool",
    "unavailableSeats", "unavailableZones"
  ]

  zoomMin = 0.2
  zoomMax = 3
  zoomFactor = 0.1
  initialZoom = 0.8
  mode = "move"
  enabled = false
  override = false
  overrideMode = "arrow"
  allocationMode = "unavailable"

  connect() {
    this.initializeSeats()
    this.initializeSeatmap()
    this.initializePicker()
  }

  // Actions
  allocateSeatOrZone(event) {
    if (this.mode == "arrow") {
      const seatRegexp = /^seat-/
      const zoneRegexp = /^zone-/
      const targetId = event.target.id

      if (targetId) {
        if (targetId.match(seatRegexp)) {
          const seatId = targetId.replace(seatRegexp, "")
          this.allocateSeatByMode(seatId, event.target)
        } else if (targetId.match(zoneRegexp)) {
          const zoneId = targetId.replace(zoneRegexp, "")
          this.allocateZoneByMode(zoneId, event.target)
        }
      }
    }
  }

  zoomIn(event) {
    this.zoomBy(this.zoomFactor)
  }

  zoomOut(event) {
    this.zoomBy(-this.zoomFactor)
  }

  selectMoveTool(event) {
    if (this.mode != "move") {
      this.selectMoveMode()
      this.disableSelect()
      this.enableZoom()
    }
  }

  selectArrowTool(event) {
    if (this.mode != "arrow") {
      this.selectArrowMode()
      this.disableSelect()
      this.disableZoom()
    }
  }

  selectBoxTool(event) {
    if (this.mode != "box") {
      this.selectBoxMode()
      this.disableZoom()
      this.enableSelect()
    }
  }

  selectUnavailableTool(event) {
    this.allocationMode = "unavailable"
    this.clearActivePicker()
    this.unavailableToolTarget.classList.add("active")
  }

  selectUnconfirmedTool(event) {
    this.allocationMode = "unconfirmed"
    this.clearActivePicker()
    this.unconfirmedToolTarget.classList.add("active")
  }

  keyDown(event) {
    switch (event.keyCode) {
      case 32:
        if (!this.enabled) {
          this.override = true
          this.overrideMode = this.mode
          this.selectMoveTool(event)
        }
        break

      case 85:
        this.selectUnavailableTool()
        break

      case 79:
        this.selectUnconfirmedTool()
        break
    }
  }

  keyUp(event) {
    if (event.keyCode == 32) {
      if (this.override) {
        this.override = false

        if (this.overrideMode == "arrow") {
          this.selectArrowTool(event)
        } else {
          this.selectBoxTool(event)
        }
      }
    }
  }

  // Private
  initializeSeats() {
    const seats = this.element.querySelectorAll("[id^='seat-']")

    for (var seat of seats) {
      seat.addEventListener("mouseover", (e) => { this.showSeatInfo(e) }, false)
      seat.addEventListener("mouseout", (e) => { this.hideSeatInfo(e) }, false)
    }

    const zones = this.element.querySelectorAll("[id^='zone-']")

    for (var zone of zones) {
      zone.addEventListener("mouseover", (e) => { this.showZoneInfo(e) }, false)
      zone.addEventListener("mouseout", (e) => { this.hideZoneInfo(e) }, false)
    }

    this.unavailableSeats = new Set(JSON.parse(this.unavailableSeatsTarget.value))
    this.unavailableZones = new Set(JSON.parse(this.unavailableZonesTarget.value))
  }

  initializeSeatmap() {
    this.svg = SVG.get("seatmap")
    this.selectArrowMode()
    this.zoomTo(this.svg.zoom() * this.initialZoom)
  }

  initializePicker() {
    this.selectUnavailableTool()
  }

  showSeatInfo(event) {
    const dataset = event.target.dataset
    const section = dataset.seatSection
    const row = dataset.seatRow
    const seat = dataset.seatNumber

    if (this.hasStatusSectionTarget) {
      if (section == "") {
        this.statusSectionTarget.textContent = "–"
      } else {
        this.statusSectionTarget.textContent = section
      }
    }

    if (row == "") {
      this.statusRowTarget.textContent = "–"
    } else {
      this.statusRowTarget.textContent = row
    }

    if (seat == "") {
      this.statusSeatTarget.textContent = "–"
    } else {
      this.statusSeatTarget.textContent = seat
    }
  }

  hideSeatInfo(event) {
    if (this.hasStatusSectionTarget) {
      this.statusSectionTarget.textContent = "–"
    }

    this.statusRowTarget.textContent = "–"
    this.statusSeatTarget.textContent = "–"
  }

  showZoneInfo(event) {
    const dataset = event.target.dataset
    const zone = dataset.zoneName

    if (zone == "") {
      this.statusSectionTarget.textContent = "–"
    } else {
      this.statusSectionTarget.textContent = zone
    }
  }

  hideZoneInfo(event) {
    this.statusSectionTarget.textContent = "–"
  }

  clearActivePicker() {
    this.unavailableToolTarget.classList.remove("active")
    this.unconfirmedToolTarget.classList.remove("active")
  }

  selectMoveMode() {
    this.mode = "move"
    this.svg.data("editor-mode", this.mode, true)
    this.moveToolTarget.classList.add("active")
    this.arrowToolTarget.classList.remove("active")
    this.boxToolTarget.classList.remove("active")
  }

  selectArrowMode() {
    this.mode = "arrow"
    this.svg.data("editor-mode", this.mode, true)
    this.arrowToolTarget.classList.add("active")
    this.moveToolTarget.classList.remove("active")
    this.boxToolTarget.classList.remove("active")
  }

  selectBoxMode() {
    this.mode = "box"
    this.svg.data("editor-mode", this.mode, true)
    this.boxToolTarget.classList.add("active")
    this.arrowToolTarget.classList.remove("active")
    this.moveToolTarget.classList.remove("active")
  }

  enableSelect() {
    this.marquee = this.svg.rect()
    this.marquee.addClass("marquee")

    this.svg.on("mousedown", this.startSelect.bind(this))
    this.svg.on("mouseup", this.endSelect.bind(this))
  }

  disableSelect() {
    this.svg.off("mousedown")
    this.svg.off("mouseup")

    if (this.marquee) {
      this.marquee.remove()
    }
  }

  startSelect(event) {
    event.preventDefault()
    this.marquee.draw(event)
  }

  endSelect(event) {
    event.preventDefault()
    this.marquee.draw(event)
    this.allocateSeats()
    this.marquee.remove()

    this.marquee = this.svg.rect()
    this.marquee.addClass("marquee")
  }

  allocateSeats() {
    const idRegexp = /^seat-/
    const seats = this.element.querySelectorAll("[id^='seat-']")

    for (var seat of seats) {
      var element = SVG.adopt(seat)
      var rbox = element.rbox(this.svg)

      if (this.marquee.inside(rbox.cx, rbox.cy)) {
        var identifier = seat.id.replace(idRegexp, "")
        this.allocateSeatByMode(identifier, seat, false)
      }
    }

    this.updateCounts()
  }

  enableZoom() {
    if (!this.enabled) {
      this.enabled = true
      this.svg.panZoom({
        zoomMin: this.zoomMin,
        zoomMax: this.zoomMax,
        zoomFactor: this.zoomFactor
      })
    }
  }

  disableZoom() {
    if (this.enabled) {
      this.enabled = false
      this.svg.panZoom(false)
    }
  }

  zoomTo(value) {
    this.svg.zoom(this.clampZoom(value))
  }

  zoomBy(value) {
    this.svg.zoom(this.clampZoom(this.svg.zoom() + value))
  }

  clampZoom(value) {
    return Math.min(this.zoomMax, Math.max(this.zoomMin, value))
  }

  allocateSeatByMode(seat, element, updateCounts = true) {
    this.clearExistingSeatAllocation(seat, element)

    if (this.allocationMode == "unavailable") {
      this.unavailableSeats.add(seat)
      element.classList = `seat ${this.allocationMode}`
    }

    if (updateCounts) {
      this.updateCounts()
    }
  }

  allocateZoneByMode(zone, element, updateCounts = true) {
    this.clearExistingZoneAllocation(zone, element)

    if (this.allocationMode == "unavailable") {
      this.unavailableZones.add(zone)
      element.classList = `zone ${this.allocationMode}`
    }

    if (updateCounts) {
      this.updateCounts()
    }
  }

  clearExistingSeatAllocation(seat, element) {
    this.unavailableSeats.delete(seat)
    element.classList = "seat"
  }

  clearExistingZoneAllocation(zone, element) {
    this.unavailableZones.delete(zone)
    element.classList = "zone"
  }

  updateCounts() {
    this.unavailableSeatsTarget.value = JSON.stringify(Array.from(this.unavailableSeats))
    this.unavailableZonesTarget.value = JSON.stringify(Array.from(this.unavailableZones))
  }
}
