import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "nativeLanguage" ]

  connect() {
    this.updateNativeLanguage();
  }

  updateNativeLanguage() {
    const option = document.getElementById("default_translation_locale").selectedOptions[0];
    this.nativeLanguageTarget.value = option.dataset.defaultTranslationsNativeLanguageValue
  }
}
