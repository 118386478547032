import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["template"]

  /** 
   * Can be used in the Front of the wallet if needed, just change the field-name data attr
   * in the view
   */

  addField() {
    let fieldName = this.data.get("field-name");
    let dynamicField = document.importNode(this.templateTarget.content, true);
    let labelField = dynamicField.getElementById("wallet_config_placeholder_name_label_placeholder_id");
    let valueField = dynamicField.getElementById("wallet_config_placeholder_name_value_placeholder_id");

    let fieldsContainer = document.querySelector(`.wallet-config--fields__${fieldName}`);
    let id = fieldsContainer.children.length;

    labelField.name = labelField.name.replace("placeholder_name", fieldName);
    labelField.id = labelField.id.replace("placeholder_name", fieldName).replace("placeholder_id", id);

    valueField.name = valueField.name.replace("placeholder_name", fieldName);
    valueField.id = valueField.id.replace("placeholder_name", fieldName).replace("placeholder_id", id);

    fieldsContainer.appendChild(dynamicField);
  }
}
