import { Controller } from "stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  static targets = [ "scannedCode", "searchResults", "spinner", "alerts", "alertsTemplate" ]

  searchButtonClicked(e) {
    e.preventDefault();

    const query = this.scannedCodeTarget.value;

    if (/^[0-9]+-\w+-[0-9]+\/?W?$/.test(query)) {
      console.log('Scanning code found');
      this.admitSearchGuest(query);
    } else {
      console.log('Scanning code not found');
      this.searchGuests(query);
    }
  }

  searchGuests(query) {
    if (this.timeout) {
      window.clearTimeout(this.timeout)
    }

    this.timeout = window.setTimeout(() => {
      if (query.length >= 1) {
        if (!this.searching) {
          const url = this.element.dataset["searchUrl"]
          var search = `${url}?q=${encodeURIComponent(query)}`

          console.log(`Search: ${query}`)

          this.searching = true

          Rails.ajax({
            url: search,
            type: "get",
            success: (response) => { this.searchResults(response) },
            error: (response) => { this.clearResults() }
          })
        }
      } else {
        this.clearResults()
      }
    }, 1000)
  }

  searchResults(response) {
    this.clearResults()

    if (response.length > 0) {
      this.showResults(response)
    } else {
      let clone = document.importNode(this.alertsTemplateTarget.content, true);
      clone.querySelector(".admission-alerts").innerText = "No results have been found"
      this.searchResultsTarget.append(clone)
    }
  }

  showResults(guests) {
    for (var guest of guests) {
      this.searchResultsTarget.appendChild(this.createGuestButton(guest))
    }

    this.searchResultsTarget.lastElementChild.classList.add("mb-3")
  }

  createGuestButton(guest) {
    var button = document.createElement("button")
    button.dataset.action = "admissions#clickGuestButton"
    button.type = "button"
    button.classList = "btn btn-block btn-outline-secondary text-left"
    button.style.fontSize = "16px"
    button.dataset.entryCode = `${guest.id}-${guest.code}-1`

    var nameCodeAndTitleElement = document.createElement("div")
    var guestTitle = guest.title == null ? "" : guest.title
    var nameCodeAndTitleText = document.createTextNode(`${guest.name} (${guest.id}-${guest.code}-1) ${guestTitle}`)

    nameCodeAndTitleElement.appendChild(nameCodeAndTitleText)
    button.appendChild(nameCodeAndTitleElement)

    var admissionsInfoElement = document.createElement("div")
    
    if (guest.admitted_count > 0) {
      var admissionsInfoText = document.createTextNode(`Admitted ${guest.admitted_count}/${guest.tickets} – ${guest.admitted_time}`)
    } else {
      var admissionsInfoText = document.createTextNode(`Max Admissions: ${guest.tickets}`)
    }

    admissionsInfoElement.appendChild(admissionsInfoText)
    button.appendChild(admissionsInfoElement)

    if (guest.affiliated_with) {
      var affiliatedWithInfoElement = document.createElement("div")
      var affiliatedWithText = document.createTextNode(`Affiliated with: ${guest.affiliated_with}`)
      affiliatedWithInfoElement.appendChild(affiliatedWithText)
      button.appendChild(affiliatedWithInfoElement)
    }

    return button
  }

  clickGuestButton(event) {
    var button = event.currentTarget
    var entryCode = button.dataset.entryCode

    this.admitSearchGuest(entryCode)
  }

  admitSearchGuest(code) {
    this.spinnerTarget.classList.remove("d-none")

    var admission_form = document.getElementById("admission_form")
    var admission_scanned_code = document.getElementById("admission_scanned_code")

    admission_scanned_code.value = code
    admission_form.submit()
  }

  clearResults() {
    this.searching = false
    const node = this.searchResultsTarget

    while (node.firstChild) {
      node.removeChild(node.firstChild)
    }
  }
}
