import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["autoconfirm", "submitBtn"]

  upload(e) {
    e.stopPropagation();

    if ( this.autoconfirmTarget.checked ) {
      const confirmed = confirm("Are you sure you want to confirm all guests on upload?");

      if ( confirmed ) {
        this.toggleSubmitBtn = true;
      } else {
        e.preventDefault();
      }

    } else {
      this.toggleSubmitBtn = true;
      return true;
    }
  }

  set toggleSubmitBtn(state) {
    this.submitBtnTarget.disabled = state;
  }
}
