import { Controller } from "stimulus"
import "element-closest"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this._setupPages()
  }

  submit(ev) {
    const valid = this._validateBirthDate(ev.target)
    if(!valid) {
      ev.preventDefault()
    }

    return valid
  }

  nextPage(ev) {
    ev.preventDefault()

    const el = ev.target
    const pageEl = el.closest(".form-page")

    if (!this._validatePage(pageEl)) {
      return
    }

    const targetPageEl = document.querySelector(
      el.getAttribute("data-target-page")
    )

    // Move to the next page
    pageEl.classList.add("d-none")
    targetPageEl.classList.remove("d-none")
  }

  _validatePage(pageEl) {
    var valid = true
    const inputs = Array.from(pageEl.querySelectorAll("input,select"))

    // Reverse to validate in top-down order (using Array#every to break out on the first error)
    inputs.reverse().forEach(el => {
      if (!el.reportValidity()) {
        valid = false
      }
    })

    if(!this._validateBirthDate(pageEl)) {
      valid = false
    }

    if(!this._validateSurveyAnswers(pageEl)) {
      valid = false
    }

    return valid
  }


  _validateBirthDate(pageEl) {
    // Validate the date of birth
    const birthDateEl = pageEl.querySelector("*[data-max-birth-date]")

    if (birthDateEl) {
      const maxDate = new Date(birthDateEl.getAttribute("data-max-birth-date"))

      // Build up the entered date
      const birthYear = pageEl.querySelector("#entrant_birth_date_1i").value
      const birthMonth = pageEl.querySelector("#entrant_birth_date_2i").value - 1
      const birthDay = pageEl.querySelector("#entrant_birth_date_3i").value

      const birthDate = new Date(birthYear, birthMonth, birthDay)

      if (birthDate > maxDate) {
        if (!pageEl.querySelector("#entrant_birth_date-error")) {
          const errorNode = document.createElement("div")
          errorNode.id = "entrant_birth_date-error"
          errorNode.classList.add("ns-invalid-age")
          errorNode.textContent = birthDateEl.getAttribute("data-age-invalid-msg")

          birthDateEl.appendChild(errorNode)
        }

        return false
      }
      else {
        const errorNode = pageEl.querySelector("#entrant_birth_date-error")
        if(errorNode) {
          errorNode.remove()
        }
      }
    }

    return true
  }

  _validateSurveyAnswers(pageEl) {
    var numberQuestions = pageEl.querySelectorAll(".ns-fan-portal-question").length
    for(i = 0; i < numberQuestions; i++) {
      var question = pageEl.querySelector(`.entrant_answer_${i}-input`)
      if(question.required && b.value.length === 0) {
        const errorNode = document.createElement("div")
        errorNode.id = "survey_answer-error"
        errorNode.classList.add("ns-invalid")

        question.appendChild(errorNode)
      }
    }
  }

  _setupPages() {
    // ---------------------------------------------------------
    // Does something have an error tag? If so, jump to its page
    const errorEls = Array.from(document.querySelectorAll("input.is-invalid,select.is-invalid")).reverse()

    if (errorEls.length > 0) {
      const pageEls = document.querySelectorAll(".form-page")

      const errorEl = errorEls[0]
      const errorPageEl = errorEl.closest(".form-page")

      pageEls.forEach(el => {
        if (el == errorPageEl) {
          el.classList.remove("d-none")
        } else {
          el.classList.add("d-none")
        }
      })
    }
    else {
      // Hide all but the first form page
      const pageEls = document.querySelectorAll(".form-page:not(.form-page-first)")
      pageEls.forEach(el => el.classList.add("d-none"))
    }
  }
}
