import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "form"]

  formChanged(event) {
    this.setChanged("true")
  }

  leavingPage(event) {
    if(this.hasChanges()) {
      event.preventDefault()

      event.returnValue = "You have unsaved changes"
      return event.returnValue
    }
  }

  allowSubmit() {
    this.setChanged("false")
  }

  setChanged(changed) {
    this.data.set("changed", changed)
  }

  hasChanges() {
    return this.data.get("changed") == "true"
  }
}
