import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "noneRadio", "urlRadio", "modelRadio",
    "urlRow", "modelRow", "urlField", "modelField"
  ]

  connect() {
    this.update()
  }

  change() {
    this.update()
  }

  hide(target) {
    target.classList.add("d-none")
  }

  show(target) {
    target.classList.remove("d-none")
  }

  disable(target) {
    target.disabled = true
  }

  enable(target) {
    target.disabled = false
  }

  update() {
    if (this.isUrl) {
      this.show(this.urlRowTarget)
      this.enable(this.urlFieldTarget)
      this.hide(this.modelRowTarget)
      this.disable(this.modelFieldTarget)
    } else if (this.isModel) {
      this.hide(this.urlRowTarget)
      this.disable(this.urlFieldTarget)
      this.show(this.modelRowTarget)
      this.enable(this.modelFieldTarget)
    } else {
      this.hide(this.urlRowTarget)
      this.disable(this.urlFieldTarget)
      this.hide(this.modelRowTarget)
      this.disable(this.modelFieldTarget)
    }
  }

  get isNone() {
    return this.noneRadioTarget.checked
  }

  get isUrl() {
    return this.urlRadioTarget.checked
  }

  get isModel() {
    return this.modelRadioTarget.checked
  }
}
