import { Controller } from "stimulus"
import SVG from "svg.js"
import "svg.draw.js"
import "svg.panzoom.js"

export default class extends Controller {
  static targets = [
    "moveTool", "arrowTool", "boxTool",
    "statusSection", "statusRow", "statusSeat", "statusAllocation",
    "unavailableTool", "unconfirmedTool",
    "unavailableSeats", "unavailableZones", "unavailableCapacity",
    "fansSeats", "fansZones", "fansAllocationCount", "fansTool",
    "guestsSeats", "guestsZones", "guestsAllocationCount", "guestsTool",
    "prSeats", "prZones", "prAllocationCount", "prTool",
    "partnersAllocationCount", "partnersTool"
  ]

  zoomMin = 0.2
  zoomMax = 3
  zoomFactor = 0.1
  initialZoom = 0.8
  mode = "move"
  enabled = false
  override = false
  overrideMode = "arrow"

  allocations = { partners: {} }
  zoneAllocations = { partners: {} }
  zoneCapacities = {}
  allocationMode = "unavailable"
  guestListId = null
  guestListColor = null
  guestListName = null

  connect() {
    this.initializeSeats()
    this.initializeSeatmap()
    this.initializePicker()
  }

  // Actions
  allocateSeatOrZone(event) {
    if (this.mode == "arrow") {
      const seatRegexp = /^seat-/
      const zoneRegexp = /^zone-/
      const targetId = event.target.id

      if (targetId) {
        if (targetId.match(seatRegexp)) {
          const seatId = targetId.replace(seatRegexp, "")
          this.allocateSeatByMode(seatId, event.target)
          this.hideSeatInfo(event);
          this.showSeatInfo(event);
        } else if (targetId.match(zoneRegexp)) {
          const zoneId = targetId.replace(zoneRegexp, "")
          this.allocateZoneByMode(zoneId, event.target)
          this.hideZoneInfo(event);
          this.showZoneInfo(event);
        }
      }
    }
  }

  zoomIn(event) {
    this.zoomBy(this.zoomFactor)
  }

  zoomOut(event) {
    this.zoomBy(-this.zoomFactor)
  }

  selectMoveTool(event) {
    if (this.mode != "move") {
      this.selectMoveMode()
      this.disableSelect()
      this.enableZoom()
    }
  }

  selectArrowTool(event) {
    if (this.mode != "arrow") {
      this.selectArrowMode()
      this.disableSelect()
      this.disableZoom()
    }
  }

  selectBoxTool(event) {
    if (this.mode != "box") {
      this.selectBoxMode()
      this.disableZoom()
      this.enableSelect()
    }
  }

  selectUnavailableTool(event) {
    this.allocationMode = "unavailable"
    this.clearActivePicker()
    this.unavailableToolTarget.classList.add("active")
  }

  selectUnconfirmedTool(event) {
    this.allocationMode = "unconfirmed"
    this.clearActivePicker()
    this.unconfirmedToolTarget.classList.add("active")
  }

  selectFansTool(event) {
    this.allocationMode = "fans"
    this.clearActivePicker()
    this.fansToolTarget.classList.add("active")
  }

  selectGuestsTool(event) {
    this.allocationMode = "guests"
    this.clearActivePicker()
    this.guestsToolTarget.classList.add("active")
  }

  selectPrTool(event) {
    this.allocationMode = "pr"
    this.clearActivePicker()
    this.prToolTarget.classList.add("active")
  }

  selectPartnersTool(event) {
    this.allocationMode = "partners"
    this.clearActivePicker()
    this.partnersToolTarget.classList.add("active")
    this.partnersToolTarget.dataset.guestListColor = event.currentTarget.dataset.guestListColor
  }

  selectPartner(event) {
    this.selectPartnersTool(event)
    this.guestListId = event.currentTarget.dataset.guestListId
    this.guestListColor = event.currentTarget.dataset.guestListColor
    this.guestListName = event.currentTarget.dataset.guestListName
  }

  keyDown(event) {
    switch (event.keyCode) {
      case 32:
        if (!this.enabled) {
          this.override = true
          this.overrideMode = this.mode
          this.selectMoveTool(event)
        }
        break

      case 85:
        this.selectUnavailableTool()
        break

      case 79:
        this.selectUnconfirmedTool()
        break

      case 70:
        if (this.hasFansToolTarget) { this.selectFansTool() }
        break

      case 71:
        if (this.hasGuestsToolTarget) { this.selectGuestsTool() }
        break

      case 80:
        if (this.hasPrToolTarget) { this.selectPrTool() }
        break
    }
  }

  keyUp(event) {
    if (event.keyCode == 32) {
      if (this.override) {
        this.override = false

        if (this.overrideMode == "arrow") {
          this.selectArrowTool(event)
        } else {
          this.selectBoxTool(event)
        }
      }
    }
  }

  // Private
  initializeSeats() {
    const seats = this.element.querySelectorAll("[id^='seat-']")

    for (var seat of seats) {
      seat.addEventListener("mouseover", (e) => { this.showSeatInfo(e) }, false)
      seat.addEventListener("mouseout", (e) => { this.hideSeatInfo(e) }, false)
    }

    const zones = this.element.querySelectorAll("[id^='zone-']")

    for (var zone of zones) {
      this.zoneCapacities[zone.dataset.zoneId] = parseInt(zone.dataset.zoneCapacity)
      zone.addEventListener("mouseover", (e) => { this.showZoneInfo(e) }, false)
      zone.addEventListener("mouseout", (e) => { this.hideZoneInfo(e) }, false)
    }

    this.allocations["unavailable"] = new Set(JSON.parse(this.unavailableSeatsTarget.value))
    this.zoneAllocations["unavailable"] = new Set(JSON.parse(this.unavailableZonesTarget.value))

    if (this.hasFansSeatsTarget) {
      this.allocations["fans"] = new Set(JSON.parse(this.fansSeatsTarget.value))
      this.zoneAllocations["fans"] = new Set(JSON.parse(this.fansZonesTarget.value))
    }

    if (this.hasGuestsSeatsTarget) {
      this.allocations["guests"] = new Set(JSON.parse(this.guestsSeatsTarget.value))
      this.zoneAllocations["guests"] = new Set(JSON.parse(this.guestsZonesTarget.value))
    }

    if (this.hasPrSeatsTarget) {
      this.allocations["pr"] = new Set(JSON.parse(this.prSeatsTarget.value))
      this.zoneAllocations["pr"] = new Set(JSON.parse(this.prZonesTarget.value))
    }

    if (this.hasPartnersAllocationCountTarget) {
      this.partnerSeats = {}
      this.partnerZones = {}

      const partnerSeatInputs = this.element.querySelectorAll("input.partner-seat[data-guest-list-id]")
      const partnerZoneInputs = this.element.querySelectorAll("input.partner-zone[data-guest-list-id]")

      for (var partner of partnerSeatInputs) {
        var guestListId = partner.dataset.guestListId

        this.partnerSeats[guestListId] = partner
        this.allocations.partners[partner.dataset.guestListId] = new Set(JSON.parse(partner.value))
      }

      for (var partner of partnerZoneInputs) {
        var guestListId = partner.dataset.guestListId

        this.partnerZones[guestListId] = partner
        this.zoneAllocations.partners[partner.dataset.guestListId] = new Set(JSON.parse(partner.value))
      }

      const partnerCounts = this.element.querySelectorAll(".partner-list-count")
      this.partnerAllocationCounts = {}

      for (var partner of partnerCounts) {
        var guestListId = partner.dataset.guestListId
        this.partnerAllocationCounts[guestListId] = partner
      }
    }
  }

  initializeSeatmap() {
    this.svg = SVG.get("seatmap")
    this.selectArrowMode()
    this.zoomTo(this.svg.zoom() * this.initialZoom)
  }

  initializePicker() {
    this.selectUnavailableTool()
  }

  showSeatInfo(event) {
    const dataset = event.target.dataset
    const section = dataset.seatSection
    const row = dataset.seatRow
    const seat = dataset.seatNumber
    const allocation = dataset.seatAllocation

    if (this.hasStatusSectionTarget) {
      if (section == "") {
        this.statusSectionTarget.textContent = "–"
      } else {
        this.statusSectionTarget.textContent = section
      }
    }

    if (row == "") {
      this.statusRowTarget.textContent = "–"
    } else {
      this.statusRowTarget.textContent = row
    }

    if (seat == "") {
      this.statusSeatTarget.textContent = "–"
    } else {
      this.statusSeatTarget.textContent = seat
    }

    if (allocation == "") {
      this.statusAllocationTarget.textContent = "–"
    } else {
      this.statusAllocationTarget.textContent = allocation
    }
  }

  hideSeatInfo(event) {
    if (this.hasStatusSectionTarget) {
      this.statusSectionTarget.textContent = "–"
    }

    this.statusRowTarget.textContent = "–"
    this.statusSeatTarget.textContent = "–"
    this.statusAllocationTarget.textContent = "–"
  }

  showZoneInfo(event) {
    const dataset = event.target.dataset
    const zone = dataset.zoneName
    const allocation = dataset.zoneAllocation

    if (zone == "") {
      this.statusSectionTarget.textContent = "–"
    } else {
      this.statusSectionTarget.textContent = zone
    }

    if (allocation == "") {
      this.statusAllocationTarget.textContent = "–"
    } else {
      this.statusAllocationTarget.textContent = allocation
    }
  }

  hideZoneInfo(event) {
    this.statusSectionTarget.textContent = "–"
    this.statusAllocationTarget.textContent = "–"
  }

  clearActivePicker() {
    this.unavailableToolTarget.classList.remove("active")
    this.unconfirmedToolTarget.classList.remove("active")

    if (this.hasFansToolTarget) {
      this.fansToolTarget.classList.remove("active")
    }

    if (this.hasGuestsToolTarget) {
      this.guestsToolTarget.classList.remove("active")
    }

    if (this.hasPrToolTarget) {
      this.prToolTarget.classList.remove("active")
    }

    if (this.hasPartnersToolTarget) {
      this.partnersToolTarget.classList.remove("active")
      delete this.partnersToolTarget.dataset.guestListId
      delete this.partnersToolTarget.dataset.guestListColor
    }

    this.guestListId = null
    this.guestListColor = null
  }

  selectMoveMode() {
    this.mode = "move"
    this.svg.data("editor-mode", this.mode, true)
    this.moveToolTarget.classList.add("active")
    this.arrowToolTarget.classList.remove("active")
    this.boxToolTarget.classList.remove("active")
  }

  selectArrowMode() {
    this.mode = "arrow"
    this.svg.data("editor-mode", this.mode, true)
    this.arrowToolTarget.classList.add("active")
    this.moveToolTarget.classList.remove("active")
    this.boxToolTarget.classList.remove("active")
  }

  selectBoxMode() {
    this.mode = "box"
    this.svg.data("editor-mode", this.mode, true)
    this.boxToolTarget.classList.add("active")
    this.arrowToolTarget.classList.remove("active")
    this.moveToolTarget.classList.remove("active")
  }

  enableSelect() {
    this.marquee = this.svg.rect()
    this.marquee.addClass("marquee")

    this.svg.on("mousedown", this.startSelect.bind(this))
    this.svg.on("mouseup", this.endSelect.bind(this))
  }

  disableSelect() {
    this.svg.off("mousedown")
    this.svg.off("mouseup")

    if (this.marquee) {
      this.marquee.remove()
    }
  }

  startSelect(event) {
    event.preventDefault()
    this.marquee.draw(event)
  }

  endSelect(event) {
    event.preventDefault()
    this.marquee.draw(event)
    this.allocateSeats()
    this.marquee.remove()

    this.marquee = this.svg.rect()
    this.marquee.addClass("marquee")
  }

  allocateSeats() {
    const idRegexp = /^seat-/
    const seats = this.element.querySelectorAll("[id^='seat-']")

    for (var seat of seats) {
      var element = SVG.adopt(seat)
      var rbox = element.rbox(this.svg)

      if (this.marquee.inside(rbox.cx, rbox.cy)) {
        var identifier = seat.id.replace(idRegexp, "")
        this.allocateSeatByMode(identifier, seat, false)
      }
    }

    this.updateCounts()
  }

  enableZoom() {
    if (!this.enabled) {
      this.enabled = true
      this.svg.panZoom({
        zoomMin: this.zoomMin,
        zoomMax: this.zoomMax,
        zoomFactor: this.zoomFactor
      })
    }
  }

  disableZoom() {
    if (this.enabled) {
      this.enabled = false
      this.svg.panZoom(false)
    }
  }

  zoomTo(value) {
    this.svg.zoom(this.clampZoom(value))
  }

  zoomBy(value) {
    this.svg.zoom(this.clampZoom(this.svg.zoom() + value))
  }

  clampZoom(value) {
    return Math.min(this.zoomMax, Math.max(this.zoomMin, value))
  }

  allocateSeatByMode(seat, element, updateCounts = true) {
    this.clearExistingSeatAllocation(seat, element)

    if (this.allocationMode == "partners") {
      element.dataset.guestListId = this.guestListId
      element.classList = `seat partners partners-${this.guestListColor}`
      this.allocations.partners[this.guestListId].add(seat)
    } else if (this.allocationMode != "unconfirmed") {
      this.allocations[this.allocationMode].add(seat)
      element.classList = `seat ${this.allocationMode}`
    }

    element.dataset.seatAllocation = this.allocationStatusForMode()

    if (updateCounts) {
      this.updateCounts()
    }
  }

  allocateZoneByMode(zone, element, updateCounts = true) {
    this.clearExistingZoneAllocation(zone, element)

    if (this.allocationMode == "partners") {
      element.dataset.guestListId = this.guestListId
      element.classList = `zone partners partners-${this.guestListColor}`
      this.zoneAllocations.partners[this.guestListId].add(zone)
    } else if (this.allocationMode != "unconfirmed") {
      this.zoneAllocations[this.allocationMode].add(zone)
      element.classList = `zone ${this.allocationMode}`
    }

    element.dataset.zoneAllocation = this.allocationStatusForMode()

    if (updateCounts) {
      this.updateCounts()
    }
  }

  allocationStatusForMode() {
    switch (this.allocationMode) {
      case "partners":
        return this.guestListName
      case "fans":
        return "Fans"
      case "guests":
        return "Guests"
      case "pr":
        return "PR"
      case "unavailable":
        return "Unavailable"
      default:
        return "Unconfirmed"
    }
  }

  clearExistingSeatAllocation(seat, element) {
    const types = ["unavailable", "fans", "guests", "pr", "partners"]

    for (var type of types) {
      if (element.classList.contains(type)) {
        if (type == "partners") {
          this.allocations.partners[element.dataset.guestListId].delete(seat)
          delete element.dataset.guestListId
        } else {
          this.allocations[type].delete(seat)
        }
      }
    }

    element.classList = "seat"
    element.dataset.seatAllocation = ""
  }

  clearExistingZoneAllocation(zone, element) {
    const types = ["unavailable", "fans", "guests", "pr", "partners"]

    for (var type of types) {
      if (element.classList.contains(type)) {
        if (type == "partners") {
          this.zoneAllocations.partners[element.dataset.guestListId].delete(zone)
          delete element.dataset.guestListId
        } else {
          this.zoneAllocations[type].delete(zone)
        }
      }
    }

    element.classList = "zone"
    element.dataset.zoneAllocation = ""
  }

  allocationCountFor(type) {
    var count = this.allocations[type].size

    for (var zone of this.zoneAllocations[type]) {
      count += this.zoneCapacities[zone]
    }

    return count
  }

  partnerAllocationCountFor(guestListId) {
    var count = this.allocations.partners[guestListId].size

    for (var zone of this.zoneAllocations.partners[guestListId]) {
      count += this.zoneCapacities[zone]
    }

    return count
  }

  updateCounts() {
    this.unavailableCapacityTarget.textContent = this.allocationCountFor("unavailable")
    this.unavailableSeatsTarget.value = JSON.stringify(Array.from(this.allocations["unavailable"]))
    this.unavailableZonesTarget.value = JSON.stringify(Array.from(this.zoneAllocations["unavailable"]))

    if (this.hasFansAllocationCountTarget) {
      this.fansSeatsTarget.value = JSON.stringify(Array.from(this.allocations["fans"]))
      this.fansZonesTarget.value = JSON.stringify(Array.from(this.zoneAllocations["fans"]))
      this.fansAllocationCountTarget.textContent = this.allocationCountFor("fans")
    }

    if (this.hasGuestsAllocationCountTarget) {
      this.guestsSeatsTarget.value = JSON.stringify(Array.from(this.allocations["guests"]))
      this.guestsZonesTarget.value = JSON.stringify(Array.from(this.zoneAllocations["guests"]))
      this.guestsAllocationCountTarget.textContent = this.allocationCountFor("guests")
    }

    if (this.hasPrAllocationCountTarget) {
      this.prSeatsTarget.value = JSON.stringify(Array.from(this.allocations["pr"]))
      this.prZonesTarget.value = JSON.stringify(Array.from(this.zoneAllocations["pr"]))
      this.prAllocationCountTarget.textContent = this.allocationCountFor("pr")
    }

    if (this.hasPartnersAllocationCountTarget) {
      var partnersTotal = 0

      for (var guestListId in this.allocations.partners) {
        var guestListTotal = this.partnerAllocationCountFor(guestListId)
        partnersTotal += guestListTotal
        this.partnerAllocationCounts[guestListId].textContent = guestListTotal
        this.partnerSeats[guestListId].value = JSON.stringify(Array.from(this.allocations.partners[guestListId]))
        this.partnerZones[guestListId].value = JSON.stringify(Array.from(this.zoneAllocations.partners[guestListId]))
      }

      this.partnersAllocationCountTarget.textContent = partnersTotal
    }
  }
}
