import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "admissionsCount",
    "additionalGuestNames"
  ]

  connect() {
    this.toggleAdditionalGuestNames()    
  }

  admissionsCountChanged() {
    this.toggleAdditionalGuestNames()
  }

  toggleAdditionalGuestNames() {
    if (this.admissionsCount > 1) {
      this.additionalGuestNames.disabled = false
    } else {
      this.additionalGuestNames.disabled = true
    }
  }

  get admissionsCount() {
    return parseInt(this.admissionsCountTarget.value)
  }

  get additionalGuestNames() {
    return this.additionalGuestNamesTarget
  }
}
