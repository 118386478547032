import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "defaultLocale", "availableTranslations", "hiddenDefaultLocale" ]

  connect() {
    this.updateCheckedTranslations();
    this.toggleNativeLanguages();
  }

  checkboxes() {
    return [...this.availableTranslationsTarget.querySelectorAll("input[type=checkbox]")];
  }

  checkedboxesTranslations() {
    return this.checkboxes().filter(input => input.checked);
  }

  selectedTranslations(){
    return this.checkedboxesTranslations()
      .map(checkbox => [checkbox.dataset.localeLabel, checkbox.dataset.locale])
  }

  disableSelectBox(checkedTranslations) {
    return checkedTranslations.length <= 1
  }

  alertIfDeselecting() {
    if ( !event.currentTarget.checked ) {
      alert("Deselecting this language and saving will delete the translations permanently from this fan portal")
    }
  }

  updateSelectedTranslations() {
    let checkedTranslations = this.selectedTranslations()
    let selectedTranslations = this.defaultLocaleTarget

    selectedTranslations.disabled = this.disableSelectBox(checkedTranslations)

    let options = checkedTranslations.map(language => {
      let option = document.createElement("option")
      option.value = language[1]
      option.textContent = language[0]

      if ( selectedTranslations.selectedOptions[0].value === language[1] ) {
        option.defaultSelected = true
      }

      return option
    })

    selectedTranslations.replaceChildren(...options)
  }

  updateCheckedTranslations() {
    let selectedTranslationLocale = this.defaultLocaleTarget.selectedOptions[0].value;

    this.checkedboxesTranslations().forEach(translation => {
      this.hiddenDefaultLocaleTarget.value = selectedTranslationLocale;
      translation.previousElementSibling.disabled = false;

      if ( translation.dataset.locale === selectedTranslationLocale ) {
        translation.disabled = true;
        translation.previousElementSibling.value = "1";
      } else {
        translation.disabled = false;
        translation.previousElementSibling.value = "0";
      }

    }, this);
  }

  toggleNativeLanguages() {
    this.checkboxes().forEach(checkbox => {
      const nativeLanguage = checkbox.parentElement.parentElement.querySelector(".ns-fan-portal-native-language");
      nativeLanguage.disabled = !checkbox.checked;
    });
  }
}
