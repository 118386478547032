import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "submitButton",
    "sendUnconfirmedRadioButton",
    "sendAllRadioButton"
  ];

  connect() {
    this.updateConfirmMessage()
  }

  updateConfirmMessage(event) {
    this.submitButtonTarget.dataset.confirm = this.confirmMessage
  }

  emailCount(count) {
    if (count === "0") {
      return "0 emails"
    } else if (count === "1") {
      return "1 email"
    } else {
      return `${count} emails`
    }
  }

  buildConfirmationMessage(emailCount) {
    return `Are you sure you want to send ${emailCount} using the ${this.templateName} template?`
  }

  get ticketsCount() {
    return this.data.get("tickets-count")
  }

  get unconfirmedTicketsCount() {
    return this.data.get("unconfirmed-tickets-count")
  }

  get templateName() {
    return this.data.get("template-name")
  }

  get confirmMessage() {
    if (this.sendAllTickets) {
      return this.buildConfirmationMessage(this.emailCount(this.ticketsCount))
    } else {
      return this.buildConfirmationMessage(this.emailCount(this.unconfirmedTicketsCount))
    }
  }

  get sendAllTickets() {
    return this.sendAllRadioButtonTarget.checked
  }
}
