import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "seriesAssociation", "seriesFormContainer" ]

  connect() {
    this.changeSeriesAssociation()
  }

  changeSeriesAssociation() {
    var container = this.seriesFormContainerTarget
    var fields = container.querySelectorAll("input")

    if (this.seriesAssociationTarget.value === "") {
      this.moveDataToForm(container)
      for (var field of fields) {
        field.removeAttribute("readonly")
        field.removeAttribute("disabled")
      }
    } else {
      // Move supplied field data to data attributes so that we can
      // clear the form (and restore it later without frustrating the user).
      //
      // We also mark the fields as disabled and remove any is-error attributes
      // so that any hightlighted-with-error fields are un-highlighted
      this.moveFormToData(container)

      for (var field of fields) {
        field.setAttribute("readonly", "readonly")
        field.setAttribute("disabled", "disabled")

        field.removeAttribute("aria-invalid")
        field.classList.remove("is-invalid")
      }
    }
  }

  // When someone chooses an existing series from the dropdown, move any
  // previously-supplied fields to data attributes. Conversely, if they
  // un-set the series bring any existing user-supplied data back
  moveFormToData(container) {
    var fields = container.querySelectorAll("input")

    for (var field of fields) {
      if (field.value !== "") {
        field.dataset.suppliedData = field.value
      }
      field.value = ""
    }
  }

  moveDataToForm(container) {
    var fields = container.querySelectorAll("input")

    for (var field of fields) {
      if (field.dataset.suppliedData) {
        field.value = field.dataset.suppliedData
      }
      delete field.dataset.suppliedData
    }
  }
}
