import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "questions", "dropdownOptions" ]

  labelSelector = "label"
  inputSelector = "input[type='text']"

  removeErrorMessage() {
    if (this.hasQuestionsTarget) {
      this.removeError(this.questionsTarget, "questions")
    } else if (this.hasDropdownOptionsTarget) {
      this.removeError(this.dropdownOptionsTarget, "dropdown_options")
    }
  }

  removeError(target, selector) {
    const error = target.querySelector(`#fan_portal_translation_${selector}-error`)

    if (error) {
      error.remove()
    }
  }

  removeQuestion(event) {
    if (confirm("Are you sure you want to remove this question?")) {
      const question = event.currentTarget.closest(".question")

      if (question) {
        question.remove()

        if (this.length > 0) {
          this.renumberQuestions()
        }
      }
    }
  }

  addQuestion(event) {
    const row = this.length + 1
    const question = document.createElement("div")

    question.id = `fan_portal_translation_question_${row}`
    question.classList = "form-group question"

    const label = document.createElement("label")
    label.id = `fan_portal_translation_question_${row}-label`
    label.htmlFor = `fan_portal_translation_question_${row}-input`
    label.textContent = `Question ${row}`

    const wrapper = document.createElement("div")
    wrapper.classList = "ns-fan-portal-question"

    const input = document.createElement("input")
    input.type = "text"
    input.name = "fan_portal_translation[questions][]"
    input.id = `fan_portal_translation_question_${row}-input`
    input.value = ""
    input.autocomplete = "off"
    input.classList = "form-control"

    const button = document.createElement("button")
    button.classList = "btn btn-lg btn-link p-0 text-danger"
    button.type = "button"
    button.ariaLabel = "Remove question"
    button.dataset.action = "fan-portal--survey#removeQuestion"

    const icon = document.createElement("i")
    icon.classList = "fal fa-times-circle"

    button.replaceChildren(icon)
    wrapper.replaceChildren(input, button)
    question.replaceChildren(label, wrapper)

    this.removeErrorMessage()
    this.questionsTarget.appendChild(question)
  }

  removeDropdownOption(event) {
    if (confirm("Are you sure you want to remove this dropdown option?")) {
      const option = event.currentTarget.closest(".option")

      if (option) {
        option.remove()

        if (this.length > 0) {
          this.renumberRows("")
        }
      }
    }
  }

  addDropdownOption(event) {
    const row = this.length + 1
    const option = document.createElement("div")

    option.id = `fan_portal_translation_dropdown_option_${row}`
    option.classList = "form-group option"

    const label = document.createElement("label")
    label.id = `fan_portal_translation_dropdown_option_${row}-label`
    label.htmlFor = `fan_portal_translation_dropdown_option_${row}-input`
    label.textContent = `Dropdown option ${row}`

    const wrapper = document.createElement("div")
    wrapper.classList = "ns-fan-portal-option"

    const input = document.createElement("input")
    input.type = "text"
    input.name = "fan_portal_translation[dropdown_options][]"
    input.id = `fan_portal_translation_dropdown_option_${row}-input`
    input.value = ""
    input.autocomplete = "off"
    input.classList = "form-control"

    const button = document.createElement("button")
    button.classList = "btn btn-lg btn-link p-0 text-danger"
    button.type = "button"
    button.ariaLabel = "Remove option"
    button.dataset.action = "fan-portal--survey#removeDropdownOption"

    const icon = document.createElement("i")
    icon.classList = "fal fa-times-circle"

    button.replaceChildren(icon)
    wrapper.replaceChildren(input, button)
    option.replaceChildren(label, wrapper)

    this.removeErrorMessage()
    this.dropdownOptionsTarget.appendChild(option)
  }

  renumberQuestions() {
    const labels = this.questionsTarget.querySelectorAll(this.labelSelector)
    const inputs = this.questionsTarget.querySelectorAll(this.inputSelector)

    for (var index = 0; index < labels.length; index++) {
      var row = index + 1
      var label = labels[index]
      var input = inputs[index]

      label.textContent = `Question ${row}`
      label.id = `fan_portal_translation_question_${row}-label`
      label.htmlFor = `fan_portal_translation_question_${row}`
      input.id = `fan_portal_translation_question_${row}`
    }
  }

  get length() {
    return this.element.querySelectorAll(this.inputSelector).length
  }
}
