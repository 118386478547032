import $ from "jquery"
import "bootstrap/js/dist/tooltip"

export function setTooltip(id, message) {
    $(`#${id}`).tooltip("hide")
        .attr("data-original-title", message)
        .tooltip("show")
}

export function hideTooltip(id) {
    $(`#${id}`).tooltip("hide")
}

export function hideTooltipInSeconds(id, seconds) {
    setTimeout(function() {
        hideTooltip(id)
    }, seconds)
}

export function copyToClipboard(textToCopy) {
    // Navigator clipboard api needs a secure context (https)
    if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(textToCopy)
            .catch(e => console.log("Copy to clipboard failed ", e));
    } else {
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
        }
    }
}