import { Controller } from "stimulus"
import * as Chartist from "chartist"
import "chartist-plugin-legend"
import "chartist-plugin-tooltips"

export default class extends Controller {
  static targets = ["attendeesChart", "attendeesChartLegend"];

  connect() {
    const guestLists = JSON.parse(this.data.get("guest-list-chart-data"));
    const controller = this;

    new Chartist.Pie(this.attendeesChartTarget, {
      series: guestLists.map(function(glta) {
        return {
          meta: glta.name,
          name: glta.name,
          value: glta.guest_count,
          rgba: glta.rgba,
          className: `ct-series-${glta.name.toLowerCase()}`
        };
      })
    },
    {
      showLabel: false,
      plugins: [
        Chartist.plugins.tooltip({
          class: "ct-tooltip"
        }),
        Chartist.plugins.legend({
          position: this.attendeesChartLegendTarget,
          clickable: false
        })
      ]
    }).on('draw', function(context) {
        if(context.type == 'slice') {
          const rgba = context.series.rgba;
          if(rgba) {
            context.element.attr({
              style: `fill: rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3] || 1})`
            });

            // Update colors on the legend to match
            controller.addRule(`.ct-legend li.ct-series-${context.index}:before`, `background-color: rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3] || 1}) !important`);
            controller.addRule(`.ct-legend li.ct-series-${context.index}:before`, `border-color: rgba(${rgba[0]}, ${rgba[1]}, ${rgba[2]}, ${rgba[3] || 1}) !important`);
        }
      }
    });
  }

  addRule(selector, css) {
    const styleEl = document.createElement('style');
    const sheet = document.head.appendChild(styleEl).sheet;
    const propText = `${css};`;

    sheet.addRule(selector, propText);
  }
}
