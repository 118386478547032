import { Controller } from "stimulus"
import SVG from "svg.js"
import "svg.draw.js"
import "svg.panzoom.js"

export default class extends Controller {
  static targets = [
    "moveTool", "arrowTool", "boxTool", "assignTool", "unassignTool",
    "statusSection", "statusRow", "statusSeat", "statusAllocation",
    "statusGuest", "unavailableTool", "unconfirmedTool",
    "seatAssignments", "unavailableSeats", "unavailableSeatsCount",
    "fansSeats", "fansSeatsCount", "fansSeatsTotal", "fansTool",
    "guestsSeats", "guestsSeatsCount", "guestsSeatsTotal", "guestsTool",
    "prSeats", "prSeatsCount", "prSeatsTotal", "prTool",
    "partnersSeatsCount", "partnersSeatsTotal", "partnersTool",
    "assignmentToolbar", "allocationToolbar", "guestMenu",
    "unavailableZones", "fansZones", "guestsZones", "prZones"
  ]

  zoomMin = 0.2
  zoomMax = 3
  zoomFactor = 0.1
  initialZoom = 0.8
  mode = "move"
  enabled = false
  override = false
  overrideMode = "arrow"

  allocations = { partners: {} }
  zoneAllocations = { partners: {} }
  zoneCapacities = {}
  allocationMode = "unavailable"
  guestListId = null
  guestListColor = null
  guestListName = null
  seatAssignments = {}

  connect() {
    this.initializeSeats()
    this.initializeSeatmap()
    this.initializePicker()
  }

  // Actions
  allocateOrAssignSeat(event) {
    const targetId = event.target.id

    if (this.mode == "arrow") {
      this.allocateSeatOrZone(event)
    } else if (targetId) {
      this.assignOrUnassignSeat(event)
    }
  }

  allocateSeatOrZone(event) {
    const seatRegexp = /^seat-/
    const zoneRegexp = /^zone-/
    const targetId = event.target.id

    if (targetId) {
      if (targetId.match(seatRegexp)) {
        const seatId = targetId.replace(seatRegexp, "")
        this.allocateSeatByMode(seatId, event.target)
        this.hideSeatInfo(event)
        this.showSeatInfo(event)
      } else if (targetId.match(zoneRegexp)) {
        const zoneId = targetId.replace(zoneRegexp, "")
        this.allocateZoneByMode(zoneId, event.target)
        this.hideZoneInfo(event)
        this.showZoneInfo(event)
      }
    }
  }

  assignOrUnassignSeat(event) {
    const idRegexp = /^seat-/
    const targetId = event.target.id

    if (targetId && targetId.match(idRegexp)) {
      const seatId = targetId.replace(idRegexp, "")

      if (this.mode == "assign") {
        this.assignSeat(seatId, event.target)
      } else if (this.mode == "unassign") {
        this.unassignSeat(seatId, event.target)
      }

      this.hideSeatInfo(event);
      this.showSeatInfo(event);
    }
  }

  zoomIn(event) {
    this.zoomBy(this.zoomFactor)
  }

  zoomOut(event) {
    this.zoomBy(-this.zoomFactor)
  }

  selectMoveTool(event) {
    this.selectTool("move", false, true)
    this.enableAllocationToolbar()
  }

  selectArrowTool(event) {
    this.selectTool("arrow", false, false)
    this.enableAllocationToolbar()
  }

  selectBoxTool(event) {
    this.selectTool("box", true, false)
    this.enableAllocationToolbar()
  }

  selectAssignTool(event) {
    this.selectTool("assign", false, false)
    this.enableAssignmentToolbar()
  }

  selectUnassignTool(event) {
    this.selectTool("unassign", false, false)
    this.enableAssignmentToolbar()
  }

  selectUnavailableTool(event) {
    this.allocationMode = "unavailable"
    this.clearActivePicker()
    this.unavailableToolTarget.classList.add("active")
  }

  selectUnconfirmedTool(event) {
    this.allocationMode = "unconfirmed"
    this.clearActivePicker()
    this.unconfirmedToolTarget.classList.add("active")
  }

  selectFansTool(event) {
    this.allocationMode = "fans"
    this.clearActivePicker()
    this.guestListId = event.currentTarget.dataset.guestListId
    this.fansToolTarget.classList.add("active")
  }

  selectGuestsTool(event) {
    this.allocationMode = "guests"
    this.clearActivePicker()
    this.guestListId = event.currentTarget.dataset.guestListId
    this.guestsToolTarget.classList.add("active")
  }

  selectPrTool(event) {
    this.allocationMode = "pr"
    this.clearActivePicker()
    this.guestListId = event.currentTarget.dataset.guestListId
    this.prToolTarget.classList.add("active")
  }

  selectPartnersTool(event) {
    this.allocationMode = "partners"
    this.clearActivePicker()
    this.partnersToolTarget.classList.add("active")
    this.partnersToolTarget.dataset.guestListColor = event.currentTarget.dataset.guestListColor
  }

  selectPartner(event) {
    this.selectPartnersTool(event)
    this.guestListId = event.currentTarget.dataset.guestListId
    this.guestListColor = event.currentTarget.dataset.guestListColor
    this.guestListName = event.currentTarget.dataset.guestListName
  }

  keyDown(event) {
    switch (event.keyCode) {
      case 32:
        if (!this.enabled) {
          this.override = true
          this.overrideMode = this.mode
          this.selectTool("move", false, true)
        }
        break

      case 85:
        this.selectUnavailableTool()
        break

      case 79:
        this.selectUnconfirmedTool()
        break

      case 70:
        if (this.hasFansToolTarget) { this.selectFansTool() }
        break

      case 71:
        if (this.hasGuestsToolTarget) { this.selectGuestsTool() }
        break

      case 80:
        if (this.hasPrToolTarget) { this.selectPrTool() }
        break
    }
  }

  keyUp(event) {
    if (event.keyCode == 32) {
      if (this.override) {
        this.override = false
        this.selectTool(this.overrideMode, false, false)
      }
    }
  }

  // Private
  initializeSeats() {
    const seats = this.element.querySelectorAll("[id^='seat-']")

    for (var seat of seats) {
      seat.addEventListener("mouseover", (e) => { this.showSeatInfo(e) }, false)
      seat.addEventListener("mouseout", (e) => { this.hideSeatInfo(e) }, false)
    }

    const zones = this.element.querySelectorAll("[id^='zone-']")

    for (var zone of zones) {
      this.zoneCapacities[zone.dataset.zoneId] = parseInt(zone.dataset.zoneCapacity)
      zone.addEventListener("mouseover", (e) => { this.showZoneInfo(e) }, false)
      zone.addEventListener("mouseout", (e) => { this.hideZoneInfo(e) }, false)
    }

    this.seatAssignments = JSON.parse(this.seatAssignmentsTarget.value)

    this.allocations["unavailable"] = new Set(JSON.parse(this.unavailableSeatsTarget.value))
    this.zoneAllocations["unavailable"] = new Set(JSON.parse(this.unavailableZonesTarget.value))

    if (this.hasFansSeatsTarget) {
      this.allocations["fans"] = new Set(JSON.parse(this.fansSeatsTarget.value))
      this.zoneAllocations["fans"] = new Set(JSON.parse(this.fansZonesTarget.value))
    }

    if (this.hasGuestsSeatsTarget) {
      this.allocations["guests"] = new Set(JSON.parse(this.guestsSeatsTarget.value))
      this.zoneAllocations["guests"] = new Set(JSON.parse(this.guestsZonesTarget.value))
    }

    if (this.hasPrSeatsTarget) {
      this.allocations["pr"] = new Set(JSON.parse(this.prSeatsTarget.value))
      this.zoneAllocations["pr"] = new Set(JSON.parse(this.prZonesTarget.value))
    }

    if (this.hasPartnersSeatsCountTarget) {
      this.partnerSeats = {}
      this.partnerZones = {}

      const partnerSeatInputs = this.element.querySelectorAll("input.partner-seat[data-guest-list-id]")
      const partnerZoneInputs = this.element.querySelectorAll("input.partner-zone[data-guest-list-id]")

      for (var partner of partnerSeatInputs) {
        var guestListId = partner.dataset.guestListId

        this.partnerSeats[guestListId] = partner
        this.allocations.partners[partner.dataset.guestListId] = new Set(JSON.parse(partner.value))
      }

      for (var partner of partnerZoneInputs) {
        var guestListId = partner.dataset.guestListId

        this.partnerZones[guestListId] = partner
        this.zoneAllocations.partners[partner.dataset.guestListId] = new Set(JSON.parse(partner.value))
      }

      const partnerCounts = this.element.querySelectorAll(".partner-list-count")
      this.partnerSeatCounts = {}

      for (var partner of partnerCounts) {
        var guestListId = partner.dataset.guestListId
        this.partnerSeatCounts[guestListId] = partner
      }

      const partnerTotals = this.element.querySelectorAll(".partner-list-total")
      this.partnerSeatTotals = {}

      for (var partner of partnerTotals) {
        var guestListId = partner.dataset.guestListId
        this.partnerSeatTotals[guestListId] = partner
      }
    }
  }

  initializeSeatmap() {
    this.svg = SVG.get("seatmap")
    this.selectTool("arrow", false, false)
    this.zoomTo(this.svg.zoom() * this.initialZoom)
  }

  initializePicker() {
    this.selectUnavailableTool()
  }

  showSeatInfo(event) {
    const dataset = event.target.dataset
    const section = dataset.seatSection
    const row = dataset.seatRow
    const seat = dataset.seatNumber
    const allocation = dataset.seatAllocation
    const guest = dataset.guestName

    if (this.hasStatusSectionTarget) {
      if (section == "") {
        this.statusSectionTarget.textContent = "–"
      } else {
        this.statusSectionTarget.textContent = section
      }
    }

    if (row == "") {
      this.statusRowTarget.textContent = "–"
    } else {
      this.statusRowTarget.textContent = row
    }

    if (seat == "") {
      this.statusSeatTarget.textContent = "–"
    } else {
      this.statusSeatTarget.textContent = seat
    }

    if (allocation == "") {
      this.statusAllocationTarget.textContent = "–"
    } else {
      this.statusAllocationTarget.textContent = allocation
    }

    if (guest == "") {
      this.statusGuestTarget.textContent = "–"
    } else {
      this.statusGuestTarget.textContent = guest
    }
  }

  hideSeatInfo(event) {
    if (this.hasStatusSectionTarget) {
      this.statusSectionTarget.textContent = "–"
    }

    this.statusRowTarget.textContent = "–"
    this.statusSeatTarget.textContent = "–"
    this.statusAllocationTarget.textContent = "–"
    this.statusGuestTarget.textContent = "–"
  }

  showZoneInfo(event) {
    const dataset = event.target.dataset
    const zone = dataset.zoneName
    const allocation = dataset.zoneAllocation

    if (zone == "") {
      this.statusSectionTarget.textContent = "–"
    } else {
      this.statusSectionTarget.textContent = zone
    }

    if (allocation == "") {
      this.statusAllocationTarget.textContent = "–"
    } else {
      this.statusAllocationTarget.textContent = allocation
    }
  }

  hideZoneInfo(event) {
    this.statusSectionTarget.textContent = "–"
    this.statusAllocationTarget.textContent = "–"
  }

  clearActivePicker() {
    this.unavailableToolTarget.classList.remove("active")
    this.unconfirmedToolTarget.classList.remove("active")

    if (this.hasFansToolTarget) {
      this.fansToolTarget.classList.remove("active")
    }

    if (this.hasGuestsToolTarget) {
      this.guestsToolTarget.classList.remove("active")
    }

    if (this.hasPrToolTarget) {
      this.prToolTarget.classList.remove("active")
    }

    if (this.hasPartnersToolTarget) {
      this.partnersToolTarget.classList.remove("active")
      delete this.partnersToolTarget.dataset.guestListId
      delete this.partnersToolTarget.dataset.guestListColor
    }

    this.guestListId = null
    this.guestListColor = null
  }

  clearActiveTool() {
    const tools = ["move", "arrow", "box", "assign", "unassign"]

    for (var tool of tools) {
      this[`${tool}ToolTarget`].classList.remove("active")
    }
  }

  selectTool(mode, selectEnabled, zoomEnabled) {
    this.clearActiveTool()

    this.mode = mode
    this.svg.data("editor-mode", mode, true)
    this[`${mode}ToolTarget`].classList.add("active")

    if (selectEnabled) {
      this.enableSelect()
    } else {
      this.disableSelect()
    }

    if (zoomEnabled) {
      this.enableZoom()
    } else {
      this.disableZoom()
    }
  }

  enableSelect() {
    this.marquee = this.svg.rect()
    this.marquee.addClass("marquee")

    this.svg.on("mousedown", this.startSelect.bind(this))
    this.svg.on("mouseup", this.endSelect.bind(this))
  }

  disableSelect() {
    this.svg.off("mousedown")
    this.svg.off("mouseup")

    if (this.marquee) {
      this.marquee.remove()
    }
  }

  startSelect(event) {
    event.preventDefault()
    this.marquee.draw(event)
  }

  endSelect(event) {
    event.preventDefault()
    this.marquee.draw(event)
    this.allocateSeats()
    this.marquee.remove()

    this.marquee = this.svg.rect()
    this.marquee.addClass("marquee")
  }

  allocateSeats() {
    const idRegexp = /^seat-/
    const seats = this.element.querySelectorAll("[id^='seat-']")

    for (var seat of seats) {
      var element = SVG.adopt(seat)
      var rbox = element.rbox(this.svg)

      if (this.marquee.inside(rbox.cx, rbox.cy)) {
        var identifier = seat.id.replace(idRegexp, "")
        this.allocateSeatByMode(identifier, seat, false)
      }
    }

    this.updateCounts()
  }

  enableZoom() {
    if (!this.enabled) {
      this.enabled = true
      this.svg.panZoom({
        zoomMin: this.zoomMin,
        zoomMax: this.zoomMax,
        zoomFactor: this.zoomFactor
      })
    }
  }

  disableZoom() {
    if (this.enabled) {
      this.enabled = false
      this.svg.panZoom(false)
    }
  }

  zoomTo(value) {
    this.svg.zoom(this.clampZoom(value))
  }

  zoomBy(value) {
    this.svg.zoom(this.clampZoom(this.svg.zoom() + value))
  }

  clampZoom(value) {
    return Math.min(this.zoomMax, Math.max(this.zoomMin, value))
  }

  allocateSeatByMode(seat, element, updateCounts = true) {
    this.clearExistingSeatAllocation(seat, element)

    if (this.allocationMode == "partners") {
      element.dataset.guestListId = this.guestListId
      element.classList = `seat partners partners-${this.guestListColor}`
      this.allocations.partners[this.guestListId].add(seat)
    } else if (this.allocationMode != "unconfirmed") {
      this.allocations[this.allocationMode].add(seat)
      element.classList = `seat ${this.allocationMode}`
    }

    element.dataset.seatAllocation = this.allocationStatusForMode()

    if (updateCounts) {
      this.updateCounts()
    }
  }

  allocateZoneByMode(zone, element, updateCounts = true) {
    this.clearExistingZoneAllocation(zone, element)

    if (this.allocationMode == "partners") {
      element.dataset.guestListId = this.guestListId
      element.classList = `zone unassigned partners partners-${this.guestListColor}`
      this.zoneAllocations.partners[this.guestListId].add(zone)
    } else if (this.allocationMode != "unconfirmed") {
      this.zoneAllocations[this.allocationMode].add(zone)
      element.classList = `zone unassigned ${this.allocationMode}`
    }

    element.dataset.zoneAllocation = this.allocationStatusForMode()

    if (updateCounts) {
      this.updateCounts()
    }
  }

  allocationStatusForMode() {
    switch (this.allocationMode) {
      case "partners":
        return this.guestListName
      case "fans":
        return "Fans"
      case "guests":
        return "Guests"
      case "pr":
        return "PR"
      case "unavailable":
        return "Unavailable"
      default:
        return "Unconfirmed"
    }
  }

  assignSeat(seat, element) {
    const selectedItem = this.guestMenuTarget.selectedOptions.item(0)
    const dataset = selectedItem.dataset

    if (dataset && dataset.guestId) {
      if (element.classList.contains("unassigned")) {
        if (element.classList.contains("unavailable")) {
          alert("Please click an available seat")
        } else {
          if (element.dataset.guestListId) {
            if (dataset.guestListId == element.dataset.guestListId) {
              element.dataset.guestId = dataset.guestId
              element.dataset.guestName = dataset.guestName
              element.classList.remove("unassigned")
              selectedItem.selected = false
              selectedItem.disabled = true
              this.selectNextAvailableGuest(dataset.guestListId)
              this.updateSeatAssignments(dataset.guestId, seat)
            } else {
              alert(`Please click a seat allocated to ${dataset.guestListName}`)
            }
          } else {
            alert("Please click an allocated seat")
          }
        }
      } else {
        alert("Please click an unassigned seat")
      }
    } else {
      alert("Please select a guest to assign to this seat")
    }
  }

  selectNextAvailableGuest(guestListId) {
    const item = this.guestMenuTarget.querySelector(`[data-guest-list-id='${guestListId}']:enabled`)

    if (item) {
      this.guestMenuTarget.value = item.value
    } else {
      this.guestMenuTarget.value = ""
    }
  }

  unassignSeat(seat, element, showAlert = true) {
    const guestId = element.dataset.guestId

    if (guestId) {
      element.dataset.guestId = ""
      element.dataset.guestName = ""
      element.classList.add("unassigned")
      this.enableGuestAndSelect(guestId)
      this.updateSeatAssignments(guestId, null)
    } else if(showAlert) {
      alert("Please click an assigned seat")
    }
  }

  updateSeatAssignments(guestId, seat) {
    this.seatAssignments[guestId] = seat
    this.seatAssignmentsTarget.value = JSON.stringify(this.seatAssignments)
    this.updateCounts()
  }

  enableGuestAndSelect(guestId) {
    const item = this.guestMenuTarget.querySelector(`[data-guest-id='${guestId}']`)

    if (item) {
      item.disabled = false
      this.guestMenuTarget.value = guestId
    }
  }

  enableAssignmentToolbar() {
    this.assignmentToolbarTarget.classList.remove("d-none")
    this.allocationToolbarTarget.classList.add("d-none")
  }

  enableAllocationToolbar() {
    this.assignmentToolbarTarget.classList.add("d-none")
    this.allocationToolbarTarget.classList.remove("d-none")
  }

  allocateSeatByMode(seat, element, updateCounts = true) {
    this.clearExistingAllocation(seat, element)

    if (this.allocationMode == "partners") {
      element.dataset.guestListId = this.guestListId
      element.classList = `seat unassigned partners partners-${this.guestListColor}`
      this.allocations.partners[this.guestListId].add(seat)
    } else if (this.allocationMode != "unconfirmed") {
      this.allocations[this.allocationMode].add(seat)
      element.classList = `seat unassigned ${this.allocationMode}`
    }

    element.dataset.guestListId = this.guestListId
    element.dataset.seatAllocation = this.allocationStatusForMode()

    this.unassignSeat(seat, element, false)

    if (updateCounts) {
      this.updateCounts()
    }
  }

  allocationStatusForMode() {
    switch (this.allocationMode) {
      case "partners":
        return this.guestListName
      case "fans":
        return "Fans"
      case "guests":
        return "Guests"
      case "pr":
        return "PR"
      case "unavailable":
        return "Unavailable"
      default:
        return "Unconfirmed"
    }
  }

  clearExistingAllocation(seat, element) {
    const types = ["unavailable", "fans", "guests", "pr", "partners"]

    for (var type of types) {
      if (element.classList.contains(type)) {
        if (type == "partners") {
          this.allocations.partners[element.dataset.guestListId].delete(seat)
          delete element.dataset.guestListId
        } else {
          this.allocations[type].delete(seat)
        }
      }
    }

    element.classList = "seat"
    element.dataset.seatAllocation = ""
  }

  clearExistingZoneAllocation(zone, element) {
    const types = ["unavailable", "fans", "guests", "pr", "partners"]

    for (var type of types) {
      if (element.classList.contains(type)) {
        if (type == "partners") {
          this.zoneAllocations.partners[element.dataset.guestListId].delete(zone)
          delete element.dataset.guestListId
        } else {
          this.zoneAllocations[type].delete(zone)
        }
      }
    }

    element.classList = "zone"
    element.dataset.zoneAllocation = ""
  }

  allocationCountFor(type) {
    var count = this.allocations[type].size

    for (var zone of this.zoneAllocations[type]) {
      count += this.zoneCapacities[zone]
    }

    return count
  }

  partnerAllocationCountFor(guestListId) {
    var count = this.allocations.partners[guestListId].size

    for (var zone of this.zoneAllocations.partners[guestListId]) {
      count += this.zoneCapacities[zone]
    }

    return count
  }

  updateCounts() {
    this.unavailableSeatsCountTarget.textContent = this.allocations["unavailable"].size
    this.unavailableSeatsTarget.value = JSON.stringify(Array.from(this.allocations["unavailable"]))
    this.unavailableZonesTarget.value = JSON.stringify(Array.from(this.zoneAllocations["unavailable"]))

    if (this.hasFansSeatsTotalTarget) {
      this.fansSeatsTarget.value = JSON.stringify(Array.from(this.allocations["fans"]))
      this.fansZonesTarget.value = JSON.stringify(Array.from(this.zoneAllocations["fans"]))
      this.fansSeatsTotalTarget.textContent = this.allocationCountFor("fans")

      const assignedFans = this.guestMenuTarget.querySelectorAll("[data-guest-list-type='fans']:disabled")
      this.fansSeatsCountTarget.textContent = assignedFans.length
    }

    if (this.hasGuestsSeatsTotalTarget) {
      this.guestsSeatsTarget.value = JSON.stringify(Array.from(this.allocations["guests"]))
      this.guestsZonesTarget.value = JSON.stringify(Array.from(this.zoneAllocations["guests"]))
      this.guestsSeatsTotalTarget.textContent = this.allocationCountFor("guests")

      const assignedGuests = this.guestMenuTarget.querySelectorAll("[data-guest-list-type='guests']:disabled")
      this.guestsSeatsCountTarget.textContent = assignedGuests.length
    }

    if (this.hasPrSeatsTotalTarget) {
      this.prSeatsTarget.value = JSON.stringify(Array.from(this.allocations["pr"]))
      this.prZonesTarget.value = JSON.stringify(Array.from(this.zoneAllocations["pr"]))
      this.prSeatsTotalTarget.textContent = this.allocationCountFor("pr")

      const assignedPr = this.guestMenuTarget.querySelectorAll("[data-guest-list-type='pr']:disabled")
      this.prSeatsCountTarget.textContent = assignedPr.length
    }

    if (this.hasPartnersSeatsTotalTarget) {
      var partnersCount = 0
      var partnersTotal = 0

      for (var guestListId in this.allocations.partners) {
        var guestListTotal = this.partnerAllocationCountFor(guestListId)
        partnersTotal += guestListTotal

        var guestListGuests = this.guestMenuTarget.querySelectorAll(`[data-guest-list-id='${guestListId}']:disabled`)
        var guestListCount = guestListGuests.length
        partnersCount += guestListCount

        this.partnerSeatCounts[guestListId].textContent = guestListCount
        this.partnerSeatTotals[guestListId].textContent = guestListTotal
        this.partnerSeats[guestListId].value = JSON.stringify(Array.from(this.allocations.partners[guestListId]))
        this.partnerZones[guestListId].value = JSON.stringify(Array.from(this.zoneAllocations.partners[guestListId]))
      }

      this.partnersSeatsCountTarget.textContent = partnersCount
      this.partnersSeatsTotalTarget.textContent = partnersTotal
    }
  }
}
