import { Controller } from "stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  static targets = [
    "newSeatmap",
    "useSeatmap",
    "seatmapFile",
    "seatmapOverride",
    "partnerLists",
    "partnerCheckboxes",
    "partnerAddBtn",
    "partnerSaveBtn",
    "partnerForm",
    "partnerFormInputField",
    "partnerFormInputFieldErrors",
    "partnerCheckboxTemplate"
  ]

  connect() {
    if (this.newSeatmapTarget.checked) {
      this.toggleUploadFields(!this.newSeatmapTarget.checked)
    }

    if (!this.hasUseSeatmapTarget) {
      this.seatmapOverrideTarget.checked = true
      this.seatmapOverrideTarget.readOnly = true
      this.seatmapOverrideTarget.disabled = true
    }

    this.toggleNewPartnerBtn(!this.partnerListsTarget.checked);
  }

  useSeatmapChanged(event) {
    this.toggleUploadFields(!this.newSeatmapTarget.checked)
  }

  toggleUploadFields(disabled) {
    this.seatmapFileTarget.disabled = disabled
    this.seatmapOverrideTarget.disabled = disabled
  }

  partnerListsChanged(event) {
    let partnerListChecked = !this.partnerListsTarget.checked;
    this.togglePartnerListCheckboxes(partnerListChecked)
    this.toggleNewPartnerBtn(partnerListChecked);
  }

  newPartnerFieldChanged(event) {
    let hasText = event.target.value.trim().length > 0;
    this.toggleSavePartnerBtn(!hasText);
  }

  newPartnerFieldErrors(errors) {
    this.partnerFormInputFieldTarget.classList.add("is-invalid");
    this.partnerFormInputFieldErrorsTarget.replaceChildren();

    errors.forEach((error) => {
      this.partnerFormInputFieldErrorsTarget.appendChild(document.createTextNode(error));
      this.partnerFormInputFieldErrorsTarget.appendChild(document.createElement("br"));
    });
  }

  toggleNewPartnerBtn(disabled) {
    this.partnerAddBtnTarget.disabled = disabled;
  }

  toggleSavePartnerBtn(disabled) {
    this.partnerSaveBtnTarget.disabled = disabled;
  }

  toggleNewPartnerForm(event) {
    if ( event ) {
      event.preventDefault();
    }

    const hideClassName = "d-none";

    if ( this.partnerFormTarget.classList.contains(hideClassName) ) {
      this.formButtons.classList.add(hideClassName)
      this.partnerAddBtnTarget.classList.add(hideClassName);
      this.partnerFormTarget.classList.remove(hideClassName);
      this.partnerFormInputFieldTarget.value = '';
      this.partnerFormInputFieldTarget.focus();
    } else {
      this.formButtons.classList.remove(hideClassName)
      this.partnerAddBtnTarget.classList.remove(hideClassName);
      this.partnerFormInputFieldTarget.classList.remove("is-invalid");
      this.partnerFormTarget.classList.add(hideClassName);
    }
  }

  createPartner(event) {
    event.preventDefault();

    let data = `[organization][name]=${this.partnerFormInputFieldTarget.value}&[organization][role]=partner`;

    Rails.ajax({
      url: "/organizations.json",
      type: "POST",
      data: data,
      success: (organization) => {
        this.addToPartnerList(organization);
      },
      error: (errors) => {
        this.newPartnerFieldErrors(errors);
      }
    });
  }

  addToPartnerList(organization) {
    let clone = document.importNode(this.partnerCheckboxTemplateTarget.content, true);
    let input = clone.querySelector("input");
    let label = clone.querySelector("label");

    input.id = `event_selected_partners_${organization.id}`;
    input.value = organization.id;
    input.checked = true;

    label.textContent = organization.name;
    label.htmlFor = `event_selected_partners_${organization.id}`;

    let partnerColumn = this.partnerCheckboxesTargets.reduce((prev, next) => {
      return prev.childElementCount <= next.childElementCount ? prev : next;
    });

    partnerColumn.appendChild(clone);
    this.toggleNewPartnerForm();
  }

  togglePartnerListCheckboxes(disabled) {
    var selector   = "input[name='event[selected_partners][]'][type=checkbox]"
    var checkboxes = this.element.querySelectorAll(selector)

    for (var checkbox of checkboxes) {
      checkbox.disabled = disabled
    }
  }

  get formButtons() {
    return document.querySelector("#form-buttons")
  }
}
