import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "collectAddress",
    "collectZipCode",
    "collectPhoneNumber",
    "requireGuestAddress",
    "requireGuestZipCode",
    "requireGuestPhoneNumber"
  ]

  connect() {
    if (this.isActive) {
      this.updateRequireGuestAddress()
      this.updateRequireGuestZipCode()
      this.updateRequireGuestPhoneNumber()
    }
  }

  updateRequireGuestAddress() {
    if (this.collectAddress) {
      this.requireGuestAddress.disabled = false
    } else {
      this.requireGuestAddress.checked = false
      this.requireGuestAddress.disabled = true
    }
  }

  updateRequireGuestZipCode() {
    if (this.collectZipCode) {
      this.requireGuestZipCode.disabled = false
    } else {
      this.requireGuestZipCode.checked = false
      this.requireGuestZipCode.disabled = true
    }
  }

  updateRequireGuestPhoneNumber() {
    if (this.collectPhoneNumber) {
      this.requireGuestPhoneNumber.disabled = false
    } else {
      this.requireGuestPhoneNumber.checked = false
      this.requireGuestPhoneNumber.disabled = true
    }
  }
  
  get isActive() {
    return this.data.get("archived") === "false"
  }
  
  get collectAddress() {
    return this.collectAddressTarget.checked
  }

  get collectZipCode() {
    return this.collectZipCodeTarget.checked
  }

  get collectPhoneNumber() {
    return this.collectPhoneNumberTarget.checked
  }
  
  get requireGuestAddress() {
    return this.requireGuestAddressTarget
  }
  
  get requireGuestZipCode() {
    return this.requireGuestZipCodeTarget
  }
  
  get requireGuestPhoneNumber() {
    return this.requireGuestPhoneNumberTarget
  }
}
