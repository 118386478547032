import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "bodyEditor", "footerEditor",
    "textColor", "backgroundColor"
  ]

  connect() {
    this.editorTextColor = this.textColor
    this.editorBackgroundColor = this.backgroundColor
  }

  textColorChanged(event) {
    this.editorTextColor = this.textColor
  }

  backgroundColorChanged(event) {
    this.editorBackgroundColor = this.backgroundColor
  }

  get textColor() {
    return this.textColorTarget.value
  }

  set editorTextColor(value) {
    this.bodyEditor.style.color = value
    this.footerEditor.style.color = value

    return value
  }

  get backgroundColor() {
    return this.backgroundColorTarget.value
  }

  set editorBackgroundColor(value) {
    this.bodyEditor.style.backgroundColor = value
    this.footerEditor.style.backgroundColor = value

    return value
  }

  get bodyEditor() {
    return this.bodyEditorTarget.querySelector("trix-editor")
  }

  get footerEditor() {
    return this.footerEditorTarget.querySelector("trix-editor")
  }
}
