import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "generateCodes",
    "generateCodesForm"
  ]

  toggleGenerateCodes() {
    this.generateCodesFormTarget.classList.remove("d-none")
  }
}
