import { Controller } from "stimulus"
import Rails from "rails-ujs"

export default class extends Controller {
  /*
    These are the main entry points for the code from a UI perspective
  */

  scanIconClicked(event) {
    event.preventDefault()
    this.startScanning()
  }

  // Callback from qr-code-scanner-exit
  exitIconClicked(event) {
    event.preventDefault()
    this.stopScanning()
  }

  /*
    Main operations: start scanning and stop scanning
  */
  startScanning() {
    this.showScanUI()
    this.startVideoFeedAndQrCodeTick()
  }

  stopScanning() {
    this.stopQrCodeTick()
    this.stopVideoFeed()
    this.hideScanUI()
  }

  // Checks the video element to see if there's a QR code in it.
  //
  // If there is a QR code, process it. Otherwise schedule another call of ourselves
  // and exit
  //
  qrCodeTick() {
    var controller = this
    var foundQrCode = false

    var videoElement = controller.videoElement

    // Snapshot the current video image into a canvas element, so that we can
    // pass it to jsQR for processing.
    if (videoElement && videoElement.videoWidth != 0 && videoElement.videoHeight != 0) {
      var canvasContainer = controller.canvasContainer
      var canvasElement = controller.canvasElement
      var canvasContext = controller.canvasContext

      var displayWidth = canvasContainer.clientWidth
      var displayHeight = canvasContainer.clientHeight

      // We don't need to QR on the whole window if the video is small within the frame,
      // and we don't need to QR the whole video frame if it's being resized to fit in the window
      var qrWidth = Math.min(displayWidth, videoElement.videoWidth)
      var qrHeight = Math.min(displayHeight, videoElement.videoHeight)

      canvasElement.width = qrWidth
      canvasElement.height = qrHeight

      // Find the video central 'box' that matches the canvas displayWidth and displayHeight, otherwise
      // we only show the top left of the video
      var videoCropLeft = null
      var videoCropTop = null

      if (displayWidth > videoElement.videoWidth) {
        videoCropLeft = 0
      } else {
        // Find the center of the incoming video stream, then move 'left' by half the
        // window width, so that the center of the window is in the center of the camera
        videoCropLeft = (videoElement.videoWidth / 2) - (displayWidth / 2)
      }

      if (displayHeight > videoElement.videoHeight) {
        videoCropTop = 0
      } else {
        // Find the center of the incoming video stream, then move 'up' by half the
        // window height, so that the center of the window is in the center of the camera
        videoCropTop = (videoElement.videoHeight / 2) - (displayHeight / 2)
      }

      // Draw the image into the canvas (top left), pulling the data from the central block in the video feed
      canvasContext.drawImage(videoElement, videoCropLeft, videoCropTop, qrWidth, qrHeight, 0, 0, qrWidth, qrHeight)

      var imageData = canvasContext.getImageData(0, 0, qrWidth, qrHeight)
      var code = jsQR(imageData.data, qrWidth, qrHeight, { inversionAttempts: "dontInvert" })

      if (!code) {
        console.log("No QR code in frame")
      } else if (!code["data"]) {
        console.log("No QR code data")
      } else {
        console.log(`QR code returned '${code["data"]}'`)

        // Codes must be in format "number-string-number"
        if (/^[0-9]+-\w+-[0-9]+\/?W?$/.test(code["data"])) {
          // Great, we found a Qr code in the right format. Stop scanning and
          // submit the data. We don't technically need to hide the UI, but it
          // improves the user experience since the scanning UI will then
          // disappear the moment there is a succesful scan - it stops the user
          // trying to continue scanning while the form is being submitted
          // in the background
          controller.hideAllElements("#qr-code-scanner-invalid-error-message")

          console.log(`Attempting guest admittance for id '${code["data"]}'`)

          controller.stopVideoFeed()
          controller.hideScanUI()

          controller.admitGuest(code["data"])
          foundQrCode = true
        } else {
          console.log(`Invalid code supplied - skipping it. Code: '${code["data"]}'`)

          // Add message saying it is an invalid code
          controller.showAllElements("#qr-code-scanner-invalid-error-message")
        }
      }

      // Try and avoid memory leaks by de-allocating objects we create
      imageData = null
      code = null
    } else {
      console.log("Video element width and height is zero. Skipping. Waiting for video to initialize properly...")
    }

    // Schedule the next execution
    if (!foundQrCode) {
      controller.qrTick = setTimeout(
        () => { controller.qrCodeTick() },
        1
      )
    } else {
      console.log("Not scheduling further qrTick runs as we have found a QR code")
    }
  }

  stopQrCodeTick() {
    console.log("stopQrCodeTick triggered")
    if (this.qrTick) {
      clearTimeout(this.qrTick)
    } else {
      console.log("Not clearing refresh timer...")
    }
  }

  disconnect() {
    this.stopQrCodeTick()
  }

  stopVideoFeed() {
    // Stop the video feed from consuming resources
    if (this.videoElement) {
      // Stop the video feed
      this.videoElement.pause()
      this.videoElement.srcObject = null

      // Don't disable the video stream, since if we ever switch to Ajax, this
      // would mean we need to re-request permission to access the camera.
      // this.videoStream.getTracks()[0].stop()

      // Not strictly necessary, but should clean up memory usage
      this.videoElement = null
    }
  }

  startVideoFeedAndQrCodeTick() {
    var controller = this

    var videoElement = document.createElement("video")
    this.videoElement = videoElement

    var canvasContainer = document.getElementById("qr-code-scanner-container")
    this.canvasContainer = canvasContainer

    var canvasElement = document.getElementById("qr-code-scanner")
    this.canvasElement = canvasElement
    this.canvasContext = canvasElement.getContext("2d")

    navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          width: { min: 640, ideal: 1920 },
          height: { min: 400, ideal: 1080 },
          aspectRatio: { ideal: 1.7777777778 },
          facingMode: "environment"
        }
    }).then(function(stream) {
      controller.hideAllElements("#qr-code-scanner-loading-message")
      controller.showAllElements("#qr-code-scanner")

      videoElement.srcObject = stream
      videoElement.setAttribute("playsinline", false)
      videoElement.play()

      controller.videoStream = stream
      controller.qrTick = setTimeout(
        () => { controller.qrCodeTick() },
        1
      )
    })
  }

  showScanUI() {
    // Make the body fill the screen and have a dark background
    document.querySelector("body").classList.add("qr-code-scanner-running", "bg-dark")

    // Hide search elements
    this.hideAllElements("nav, div#subnav, .alert, #admissions_controller_container, #qr-code-scanner-invalid-error-message")

    // Display scan elements
    this.showAllElements("#qr-code-scanner-exit, #qr-code-scanner-container, #qr-code-scanner-loading-message")
  }

  hideScanUI() {
    // Make the body fill the screen and have a dark background
    document.querySelector("body").classList.remove("qr-code-scanner-running", "bg-dark")

    // Hide scan elements
    this.hideAllElements("#qr-code-scanner-exit, #qr-code-scanner-container, #qr-code-scanner-loading-message", "#qr-code-scanner-invalid-error-message")

    // Show search elements
    this.showAllElements("nav, div#subnav, .alert, #admissions_controller_container")
  }

  showAllElements(element_selector) {
    var elements = document.querySelectorAll(element_selector)
    for (var element of elements) {
        element.classList.remove("d-none")
    }
  }

  hideAllElements(element_selector) {
    var elements = document.querySelectorAll(element_selector)
    for (var element of elements) {
        element.classList.add("d-none")
    }
  }

  admitGuest(code) {
    var admission_form = document.getElementById("admission_form")
    var admission_scanned_code = document.getElementById("admission_scanned_code")
    admission_scanned_code.value = code
    admission_form.submit()
  }
}
