import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "autoSendNone", "configuration", "configurationItem"
  ]

  connect() {
    if (this.autoSendNone) {
      this.hideConfiguration()
    } else {
      this.showConfiguration()
    }
  }

  hideConfiguration() {
    this.configurationTarget.classList.add("d-none")

    this.configurationItemTargets.forEach((el, i) => {
      el.disabled = true
    })
  }

  showConfiguration() {
    this.configurationTarget.classList.remove("d-none")

    this.configurationItemTargets.forEach((el, i) => {
      el.disabled = false
    })
  }

  get autoSendNone() {
    return this.autoSendNoneTarget.checked
  }
}
