import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "numberOfEntrantsField",
    "expiryHoursField",
    "fanTypeMenu",
    "autoSendMenu",
    "fanOrderMenu",
    "inviteEntrantsButton",
    "numberOfAccessCodesField",
    "admissionsCountField"
  ]

  connect() {
    this.autoSend = "invite"
  }

  notifyChange() {
    var conversion_form = document.getElementById("conversion_form")
    var fields = conversion_form.querySelectorAll("input, select")

    for (var field of fields) {
      field.setAttribute("readonly", "readonly")
      field.setAttribute("disabled", "disabled")
    }
  }

  alertIfOverAllocating() {
    var fan_type_selector = document.getElementById("search_form_params_fan_type")

    var supplied_allocation_field = document.getElementById("search_form_params_number_of_entrants")
    var supplied_allocation = parseInt(supplied_allocation_field.value)
    if (supplied_allocation < 0) {
      supplied_allocation_field.classList.add("is-invalid")
      event.preventDefault()
      return
    }

    var dataset = fan_type_selector[fan_type_selector.selectedIndex].dataset
    if (!(dataset["invitedGuestAdmissionsCount"] && dataset["allocationTotal"])) {
      fan_type_selector.classList.add("is-invalid")
      event.preventDefault()
      return
    }

    var invited_guest_admissions_count = parseInt(dataset["invitedGuestAdmissionsCount"])
    var allocation_zone_total = parseInt(dataset["allocationTotal"])
    var remaining = allocation_zone_total - invited_guest_admissions_count

    var number_access_codes_field = document.getElementById("access_codes")
    var number_access_codes = parseInt(number_access_codes_field.value)

    var message = null
    if (remaining > supplied_allocation && supplied_allocation <= number_access_codes) {
      message = `There are ${remaining} spaces free in the zone. Invite ${supplied_allocation} guests?`
    } else if (remaining <= supplied_allocation && supplied_allocation <= number_access_codes) {
      message = `This action will invite more people than there are spaces in the zone. There are ${remaining} spaces free. Are you sure you want to invite ${supplied_allocation} guests?`
    } else if (supplied_allocation > number_access_codes) {
      message = `This action will invite more people than there are available access codes. There are ${number_access_codes} access codes available. Are you sure you want to invite ${supplied_allocation} guests?`
    }

    var confirmed = window.confirm(message)
    if (!confirmed) {
      event.preventDefault()
      return
    }
  }

  updateInviteButton(e) {
    if (this.fanOrder == "Random") {
      this.inviteButton.value = "Invite Random Entrants"
    } else {
      this.inviteButton.value = "Invite Entrants"
    }

    if (this.formIsValid()) {
      this.inviteButton.disabled = false
    } else {
      this.inviteButton.disabled = true
    }
  }

  formIsValid() {
    if (!(parseInt(this.numberOfEntrants) >= 0)) {
      return false
    } else if (!(parseInt(this.expiryHours) >= 0)) {
      return false
    } else if (!(parseInt(this.admissionsCount) >= 0)) {
      return false
    } else if (this.fanType == "") {
      return false
    } else if (this.autoSend == "") {
      return false
    } else if (this.fanOrder == "") {
      return false
    } else {
      return true
    }
  }

  get numberOfEntrants() {
    return this.numberOfEntrantsFieldTarget.value
  }

  get admissionsCount() {
    return this.admissionsCountFieldTarget.value
  }

  get expiryHours() {
    return this.expiryHoursFieldTarget.value
  }

  get fanType() {
    return this.fanTypeMenuTarget.value
  }

  get autoSend() {
    return this.autoSendMenuTarget.value
  }

  set autoSend(value) {
    this.autoSendMenuTarget.value = value
  }

  get fanOrder() {
    return this.fanOrderMenuTarget.value
  }

  get inviteButton() {
    return this.inviteEntrantsButtonTarget
  }
}
