import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "autoCloseOff", "autoCloseOn", "autoCloseLevel"
  ]

  connect() {
    if (this.autoClose) {
      this.enableConfiguration()
    } else {
      this.disableConfiguration()
    }
  }

  enableConfiguration() {
    this.autoCloseLevelTarget.disabled = false
  }

  disableConfiguration() {
    this.autoCloseLevelTarget.classList.remove("is-invalid")
    this.autoCloseLevelTarget.disabled = true
    this.autoCloseLevelTarget.value = ""
  }

  get autoClose() {
    return this.autoCloseOnTarget.checked;
  }
}
