import "bootstrap"

import Rails from "rails-ujs"
Rails.start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { jsQR } from "jsqr"
import { Trix } from "trix"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

import "../images"
import "../stylesheets"
